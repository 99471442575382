import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IProduct from "models/product";

interface IHazardousSubstancesFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
};

const labelStyles = {
  margin: 0,
}

const HazardousSubstancesFields = ({ state, setState}: IHazardousSubstancesFieldsDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%'}}>
      <FormControl component="fieldset">
        <InputLabel 
          heading="Innehåller produkten något ämne på EU:s kandidatlista i en mängd som överstiger 0,1 viktprocent av produktens innehåll?"
          text="Kandidatlistan är en lista med cirka 200 särskilt farliga ämnen. Om din produkt innehåller något ämne på kandidatlistan kan det innebära att du har särskilda krav."
          link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/reach-forordningen/kandidatforteckningen---eus-lista-over-sarskilt-farliga-amnen"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.hazardousSubstancesOver1}
          name="hazardousSubstancesOver1"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
      {state?.hazardousSubstancesOver1 && (
        <>
          <InputLabel
            heading="Lista särskilt farliga ämnen och information för säker användning"
            text="Om en vara innehåller mer än 0,1 viktprocent av ett ämne som finns på kandidatförteckningen ska du som är leverantör av varan lämna information så att mottagaren kan använda varan på ett säkert sätt."
            link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/reach-forordningen/kandidatforteckningen---eus-lista-over-sarskilt-farliga-amnen"
            required
          />
          <InputTextField
            state={state}
            setState={setState}
            inputName="informationHazardousSubstances"
            value={state.informationHazardousSubstances}
            placeholder="Lista särskilt farliga ämnen och information för säker användning"
            onChange={handleChange}
          />
        </>
      )}
      <FormControl component="fieldset">
        <InputLabel 
          heading="Följer produkten Produktsäkerhetslagen 2004:451?"
          text="Denna lag syftar till att säkerställa att varor och tjänster som tillhandahålls konsumenter inte orsakar personskador."
          link="https://www.riksdagen.se/sv/dokument-och-lagar/dokument/svensk-forfattningssamling/produktsakerhetslag-2004451_sfs-2004-451/"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.complianceProductSafety2004451}
          name="complianceProductSafety2004451"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default HazardousSubstancesFields;
