import {
    Dashboard as DashboardIcon,
    StoreMallDirectory as ProducerIcon,
    LocalGroceryStore as ProductIcon,
    LocalShippingOutlined as OrderIcon,
    PlaylistAddCheck as ApprovalProductsIcon,
    Business as OrganizationsIcon,
    AssignmentTurnedIn as ProducersApprovalIcon,
    Poll,
    ReportProblemOutlined,
    SvgIconComponent,
    HistoryOutlined,
    CloudUploadOutlined,
} from '@material-ui/icons';
import Dashboard from './pages/shared/All_Dashboard';
import Producers from './pages/organization/Organization_Producers';
import ShowProducer from './pages/organization/Organization_ShowProducer';
import Products from './pages/organization/Organization_Products';
import { ProductListWithSelectedId } from './App';
import ProducerProducts from './pages/organization/Organization_ViewProducerProducts';
import AddProducer from './pages/NOTSET_AddProducer';
import AddProduct from './pages/producer/Producer_AddProduct';
import OrderDashboardPage from './components/OrderDashboard';
import OrderPage from './pages/Order';
import NotificationPage from './components/NotificationContent';
import ProducerOwnProducts from './pages/producer/Producer_OwnProductsPage';
import ProducerEditProduct from './pages/producer/Producer_EditProduct';
import ProducerBulkImport from 'pages/producer/Producer_BulkImport';
import StoreBulkImport from 'pages/store/Store_BulkImport';
// Producer
import ShowMyProfile from './pages/producer/Producer_MyPage';

// Superuser
import AllProductsPage from './pages/superuser/Superuser_AllProductsPage';
import SuperuserProductsPage from './pages/superuser/Superuser_ProductsApprovalPage';
import TEMP_SuperuserDescSize from './pages/superuser/Superuser_TEMP_desc_size';
import SuperuserFailedProducts from './pages/superuser/SuperuserFailedProducts';
import SuperuserApprovalForm from './pages/superuser/Superuser_ApprovalForm';
import SuperuserOrganizations from './pages/superuser/Superuser_Organizations';
import SuperuserCreateOrganization from './pages/superuser/Superuser_CreateOrganization';
import SuperuserEditOrganization from './pages/superuser/Superuser_EditOrganization';
import SuperuserAllProducersPage from './pages/superuser/Superuser_AllProducers';
import SuperuserEditProducer from './pages/superuser/Superuser_EditProducer';
import SuperuserNotApprovedProducers from './pages/superuser/Superuser_ProducersApprovalPage';
import SuperuserData from './pages/superuser/data';
import SuperuserExportProducts from './pages/superuser/Superuser_ExportProducts';
import SuperuserIcaExportProducts from './pages/superuser/Superuser_ICA_ExportProducts';
import BulkImport from 'pages/superuser/Superuser_BulkImport';
import BulkImportNew from 'pages/superuser/Superuser_BulkImportNew';
import SuperuserErrors from './pages/superuser/errors';

import ErrorIcon from '@material-ui/icons/Error';
import ExportHistory from 'pages/superuser/history';
import { LOCAL_STORE_ITEMS } from 'components/product/BulkImport/constants';
import { getStepPath } from 'components/product/BulkImport/utils';

export interface IMenuItem {
    title: string;
    icon: SvgIconComponent;
};

export interface IMenuItems {
    [key: string]: IMenuItem;
}

export const MENU_ITEMS: IMenuItems = {
    dashboard: { title: 'Dashboard', icon: DashboardIcon },
    me: { title: 'Me', icon: ProducerIcon },
    meProducts: { title: 'Products', icon: ProductIcon },
    meBulkImport: { title: 'Bulk Import', icon: CloudUploadOutlined },
    producers: { title: 'Producers', icon: ProducerIcon },
    products: { title: 'Products', icon: ProductIcon },
    storeProducts: { title: 'My Products', icon: ProductIcon },
    storeAllProducts: { title: 'All Products', icon: ProductIcon },
    storeBulkImport: { title: 'Bulk Import', icon: CloudUploadOutlined },
    orders: { title: 'Orders', icon: OrderIcon },
    superuserProducts: {
        title: 'Product Approval',
        icon: ApprovalProductsIcon,
    },
    superuserTmp: {
      title: 'Descriptive size',
      icon: ApprovalProductsIcon,
    },
    failedProducts: {
      title: 'Failed Products',
      icon: ReportProblemOutlined,
    },
    // superuserExportProducts: {
    //     title: 'Export Products',
    //     icon: RestoreIcon,
    // },
    // superuserExportNonFoodProducts: {
    //     title: 'Export Non Food',
    //     icon: RestoreIcon,
    // },
    superuserProducers: {
      title: 'Producers Approval',
      icon: ProducersApprovalIcon,
    },
    superuserAllProducts: { title: 'All Products', icon: ProductIcon },
    superuserAllOrganizations: {
        title: 'Organizations',
        icon: OrganizationsIcon,
    },
    superuserAllProducers: { title: 'All Producers', icon: ProducerIcon },
    superuserData: { title: 'Data', icon: Poll },
    producerData: { title: 'Data', icon: Poll },
    superuserErrors: { title: 'Errors', icon: ErrorIcon },
    exportHistory: { title: 'Export history', icon: HistoryOutlined },
    // bulkImport: { title: 'Bulk import', icon: CloudUploadOutlined },
    bulkImportNew: { title: 'Bulk import new', icon: CloudUploadOutlined },
};

export interface IRouteItem {
    path: string;
    getNavPath?: () => string;
    exact: boolean;
    component: any; // TODO any
}

export interface IRouteItems {
    [key: string]: IRouteItem;
}

export const ROUTES: IRouteItems = {
    dashboard: { path: '/', exact: true, component: Dashboard },
    me: { path: '/me', exact: true, component: ShowMyProfile },
    meProducts: {
        path: '/products',
        exact: true,
        component: ProducerOwnProducts,
    },
    meBulkImport: {
        path: '/bulk-import',
        exact: true,
        component: ProducerBulkImport,
    },
    meBulkImportNew: {
      path: '/bulk-import-new/:step',
      getNavPath: () => `/bulk-import-new/${getStepPath()}`,
      exact: false,
      component: ProducerBulkImport,
    },
    meShowProduct: {
        path: '/products/:id/show',
        exact: true,
        component: ProducerOwnProducts,
    },
    storeShowProduct: {
        path: '/me/products/:id/show',
        exact: true,
        component: ProducerOwnProducts,
    },
    meEditProduct: {
        path: '/products/:id/edit',
        exact: true,
        component: ProducerEditProduct,
    },
    storeEditProduct: {
        path: '/me/products/:id/edit',
        exact: true,
        component: ProducerEditProduct,
    },
    meEdit: {
      path: '/me/:id/edit',
      exact: true,
      component: SuperuserEditProducer,
    },
    producers: { path: '/producers', exact: true, component: Producers },
    showProducer: {
        path: '/producers/:id/show',
        exact: true,
        component: ShowProducer,
    },
    products: { path: '/products', exact: true, component: Products },
    storeProducts: { path: '/me/products', exact: true, component: ProducerOwnProducts },
    storeAllProducts: { path: '/products', exact: true, component: Products },
    storeBulkImport: { path: '/bulk-import', exact: true, component: StoreBulkImport },
    showProduct: {
        path: '/products/:id/show',
        exact: true,
        component: ProductListWithSelectedId,
    },
    producerShowProduct: {
        path: '/producers/:producerId/products/:id/show',
        exact: true,
        component: ProducerProducts,
    },
    producerProducts: {
        path: '/producers/:id/products',
        exact: true,
        component: ProducerProducts,
    },
    producerData: {
        path: '/producers/data',
        exact: true,
        component: SuperuserData,
    },
    addProducer: {
        path: '/producers/addproducer',
        exact: true,
        component: AddProducer,
    },
    addProduct: {
        path: '/products/addproduct',
        exact: true,
        component: AddProduct,
    },
    storeAddProduct: {
        path: '/me/products/addproduct',
        exact: true,
        component: AddProduct,
    },
    orders: { path: '/orders', exact: true, component: OrderDashboardPage },
    orderStep: { path: '/order/:step', exact: false, component: OrderPage },
    orderNotification: {
        path: '/notification/:groupOrderNumber',
        exact: true,
        component: NotificationPage,
    },
    superuserProducts: {
        path: '/superuser/products',
        exact: true,
        component: SuperuserProductsPage,
    },
    superuserTmp: {
      path: '/superuser/descriptivesize',
      exact: true,
      component: TEMP_SuperuserDescSize,
    },
    superuserFailedProducts: {
        path: '/superuser/failed',
        exact: true,
        component: SuperuserFailedProducts,
    },
    superuserExportProducts: {
        path: '/superuser/export',
        exact: true,
        component: SuperuserExportProducts,
    },
    superuserIcaExportProducts: {
        path: '/superuser/ica-export',
        exact: true,
        component: SuperuserIcaExportProducts,  
    },
    superuserExportNonFoodProducts: {
        path: '/superuser/export?nonfood=1',
        exact: true,
        component: SuperuserExportProducts,
    },
    superuserProducers: {
      path: '/superuser/producers',
      exact: true,
      component: SuperuserNotApprovedProducers,
  },
    superuserAllProductsList: {
        path: '/products',
        exact: true,
        component: AllProductsPage,
    },
    superuserOrganizations: {
        path: '/superuser/organizations',
        exact: true,
        component: SuperuserOrganizations,
    },
    superuserApprovalForm: {
        path: '/superuser/products/:id/approval',
        exact: true,
        component: SuperuserApprovalForm,
    },
    superuserCreateOrganization: {
        path: '/superuser/addorganization',
        exact: true,
        component: SuperuserCreateOrganization,
    },
    superuserEditOrganization: {
        path: '/superuser/organization/:id/edit',
        exact: true,
        component: SuperuserEditOrganization,
    },
    superuserAllProducersList: {
        path: '/producers',
        exact: true,
        component: SuperuserAllProducersPage,
    },
    superuserEditProducer: {
      path: '/superuser/producer/:id/edit',
      exact: true,
      component: SuperuserEditProducer,
    },
    superuserAddProducer: {
      path: '/superuser/addproducer',
      exact: true,
      component: SuperuserEditProducer,
    },
    superuserData: {
      path: '/superuser/data',
      exact: true,
      component: SuperuserData,
    },
    superuserErrors: {
        path: '/superuser/errors',
        exact: true,
        component: SuperuserErrors,
    },
    exportHistory: {
        path: '/superuser/export-history',
        exact: true,
        component: ExportHistory,
    },
    bulkImport: {
      path: '/superuser/bulk-import',
      exact: true,
      component: BulkImport,
    },
    bulkImportNew: {
      path: '/superuser/bulk-import-new/:step',
      getNavPath: () => `/superuser/bulk-import-new/${getStepPath()}`,
      exact: false,
      component: BulkImportNew,
    },
};

export interface IPermission {
    route: IRouteItem;
    menu?: IMenuItem;
}

export interface IPermissions {
    [key: string]: IPermission[];
}

const PERMISSIONS: IPermissions = {
    admin: [
        { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
        { route: ROUTES.producers, menu: MENU_ITEMS.producers },
        { route: ROUTES.products, menu: MENU_ITEMS.products },
        { route: ROUTES.addProducer },
        { route: ROUTES.addProduct },
        { route: ROUTES.showProduct },
        { route: ROUTES.showProducer },
        { route: ROUTES.producerProducts },
        { route: ROUTES.producerShowProduct },
    ],
    logistician: [
        { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
        { route: ROUTES.producers, menu: MENU_ITEMS.producers },
        { route: ROUTES.products, menu: MENU_ITEMS.products },
        { route: ROUTES.orders, menu: MENU_ITEMS.orders },
        { route: ROUTES.orderStep },
        { route: ROUTES.orderNotification },
        { route: ROUTES.showProduct },
        { route: ROUTES.showProducer },
        { route: ROUTES.producerProducts },
        { route: ROUTES.producerShowProduct },
    ],
    producer: [
        { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
        { route: ROUTES.me, menu: MENU_ITEMS.me },
        { route: ROUTES.meProducts, menu: MENU_ITEMS.meProducts },
        { route: ROUTES.meShowProduct },
        { route: ROUTES.orderNotification },
        { route: ROUTES.orders, menu: MENU_ITEMS.orders },
        { route: ROUTES.addProduct },
        { route: ROUTES.meEditProduct },
        { route: ROUTES.meEdit },
        { route: ROUTES.producerData },
        // { route: ROUTES.meBulkImport, menu: MENU_ITEMS.meBulkImport },
        { route: ROUTES.meBulkImportNew, menu: MENU_ITEMS.meBulkImport },
    ],
    superuser: [
        { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
        {
            route: ROUTES.superuserData,
            menu: MENU_ITEMS.superuserData,
        },
        { route: ROUTES.superuserProducts, menu: MENU_ITEMS.superuserProducts },
        // { route: ROUTES.superuserTmp, menu: MENU_ITEMS.superuserTmp },
        { route: ROUTES.superuserProducers, menu: MENU_ITEMS.superuserProducers },
        { route: ROUTES.superuserOrganizations, menu: MENU_ITEMS.superuserAllOrganizations },
        { route: ROUTES.superuserAllProductsList, menu: MENU_ITEMS.superuserAllProducts },
        { route: ROUTES.superuserAllProducersList, menu: MENU_ITEMS.superuserAllProducers },
        { route: ROUTES.superuserApprovalForm },
        { route: ROUTES.superuserCreateOrganization },
        { route: ROUTES.superuserEditOrganization },
        { route: ROUTES.superuserEditProducer },
        { route: ROUTES.superuserAddProducer },
        { route: ROUTES.producerProducts },
        { route: ROUTES.superuserFailedProducts, menu: MENU_ITEMS.failedProducts },
        { route: ROUTES.superuserExportProducts },
        { route: ROUTES.superuserIcaExportProducts },
        // { route: ROUTES.superuserExportProducts, menu: MENU_ITEMS.superuserExportProducts },
        { route: ROUTES.superuserExportNonFoodProducts, menu: MENU_ITEMS.superuserExportNonFoodProducts },
        { route: ROUTES.superuserErrors, menu: MENU_ITEMS.superuserErrors },
        { route: ROUTES.exportHistory, menu: MENU_ITEMS.exportHistory },
        { route: ROUTES.bulkImport, menu: MENU_ITEMS.bulkImport },
        { route: ROUTES.bulkImportNew, menu: MENU_ITEMS.bulkImportNew },
    ],
    quality: [
      { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
      { route: ROUTES.superuserProducts, menu: MENU_ITEMS.superuserProducts },
      //   { route: ROUTES.superuserTmp, menu: MENU_ITEMS.superuserTmp },
      { route: ROUTES.superuserApprovalForm },
      { route: ROUTES.superuserFailedProducts, menu: MENU_ITEMS.failedProducts },
    //   { route: ROUTES.superuserExportProducts, menu: MENU_ITEMS.superuserExportProducts },
    ],
    store: [
        { route: ROUTES.dashboard, menu: MENU_ITEMS.dashboard },
       
        { route: ROUTES.me, menu: MENU_ITEMS.me },
        { route: ROUTES.meEdit },
       
        { route: ROUTES.showProduct },
        { route: ROUTES.storeEditProduct },
        { route: ROUTES.storeShowProduct },
        { route: ROUTES.producerShowProduct },
        { route: ROUTES.storeAddProduct },

        { route: ROUTES.storeProducts, menu: MENU_ITEMS.storeProducts },
        { route: ROUTES.producers, menu: MENU_ITEMS.producers },
        { route: ROUTES.storeAllProducts, menu: MENU_ITEMS.storeAllProducts },
        // { route: ROUTES.addProducer },
        { route: ROUTES.showProducer },
        { route: ROUTES.producerProducts },
        // { route: ROUTES.storeBulkImport, menu: MENU_ITEMS.storeBulkImport },
    ],
};

// For when user is both admin and logistician
PERMISSIONS.adminlogistician = PERMISSIONS.logistician;

export default PERMISSIONS;
