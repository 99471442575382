import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import VisionItem from './item';
import useViewport from 'hooks/useViewports';

const useStyles = makeStyles(theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const sliceString = (value, max = 100) => {
  let result = value;
  if (value && value.length > max) {
    result = "...";
    result += value.slice(-max);
  }
  return result;
};

const Vision = ({ images, state, setState }) => {
  const { smUp } = useViewport();

  const classes = useStyles();
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading} style={{ flexBasis: smUp ? undefined : '100%' }}>
          {smUp ? 'Picture meta-data' : 'Här sparar vi den information vi kan läsa ut från produkt-bilderna'}
        </Typography>
        {smUp && (
          <Typography className={classes.secondaryHeading}>
            (Här sparar vi den information vi kan läsa ut från produkt-bilderna)
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
          {(!images || images.length === 0) && (
            <p><b>No images...</b></p>
          )}
          {images?.length > 0 && images.map((image, imageIndex) => (
            <VisionItem
              image={image}
              state={state}
              setState={setState}
              title={
                smUp ? (
                  <>
                    <span>image {imageIndex + 1}: </span>
                    <a href={image?.pictureUrl} target="_blank" rel="noopener noreferrer">
                      {sliceString(image?.pictureUrl)}
                    </a>
                  </>
                ) : (
                  <a
                    href={image?.pictureUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textAlign: 'center', flexGrow: 1 }}
                  >
                    Bild {imageIndex + 1}
                  </a>
                )
              }
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default Vision;
