import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import _ from 'lodash';

import IProduct from 'models/product';
import { PRODUCT_NUMBER_FIELD } from 'components/constants-ts';

import { loadExcelFile } from './loadExcelFile';
import { isErrorCell } from './cell';
import { IImportColumn, IProductExcel } from '../../types';
import { STATUS_COLORS } from '../../constants';
import { findExcelHeaderRowIndex, getRemovedProductIndexs } from '.';

export const writeExcelFile = async (
  columnList: IImportColumn[],
  dataList: IProductExcel[],
  oldFile: File,
  { fileName = 'export_file' } = {}
) => {
  const workbook = await loadExcelFile(oldFile);

  if (!workbook) return;

  const worksheet = workbook.worksheets[0];

  const excelFileDataWithEmptyRow = (worksheet as any)._rows?.map((row: any) => row?.values || []) || [];
  const headerRowIndex = findExcelHeaderRowIndex(excelFileDataWithEmptyRow);

  const fillExcelCell = (params: { col: number; row: number; color?: string }) => {
    const { color, col } = params;
    const row = params.row + headerRowIndex;

    const oldStyles = worksheet.getRow(row).getCell(col).style;
    let fill: Excel.Fill = { type: 'pattern', pattern: 'none' };
    if (color) {
      fill.pattern = 'solid';
      fill.fgColor = { argb: color.replace('#', '') };
    }
    worksheet.getRow(row).getCell(col).style = { ...oldStyles, fill };
  };

  const updateExcelCellValue = (params: { col: number; row: number; value: any; field: keyof IProduct }) => {
    const { col, value, field } = params;
    const row = params.row + headerRowIndex;

    if (_.isNil(value)) return;

    // try to convert value to number if possible
    if (PRODUCT_NUMBER_FIELD.includes(field)) {
      let numberValue: string = _.toString(value).trim();
      if (/^\d+$/.test(numberValue)) {
        worksheet.getRow(row).getCell(col).value = parseFloat(numberValue);
        return;
      }
    }

    worksheet.getRow(row).getCell(col).value = value as string | Date;
  };

  // insert missing column to excel file
  columnList.forEach((column, index) => {
    if (column.isMissing) {
      worksheet.spliceColumns(index + 1, 0, [column.title]);
      worksheet.getColumn(index + 1).width = 30;
    }
  });

  // fill color for header cells
  columnList.forEach(({ color }, index) => {
    fillExcelCell({ row: 1, col: index + 1, color: color === STATUS_COLORS.NORMAL ? undefined : color });
  });

  // delete "removed products"
  const removedProductIndexs = getRemovedProductIndexs();
  removedProductIndexs
    .sort((prev, next) => next - prev)
    .forEach(rowIndex => {
      worksheet.spliceRows(headerRowIndex + rowIndex + 2, 1);
    });

  // fill color and update new value for body cells
  dataList.forEach((data, rowIndex) => {
    columnList.forEach((column, colIndex) => {
      const isError = isErrorCell(column, data);
      const row = rowIndex + 2;
      const col = colIndex + 1;
      fillExcelCell({ row, col, color: isError ? STATUS_COLORS.ERROR : undefined });

      const updatedValue = data._editingData?.[column.field];
      updateExcelCellValue({ row, col, value: updatedValue, field: column.field });
    });
  });

  try {
    // write the content using writeBuffer
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${fileName}.xlsx`);
  } catch {}
};
