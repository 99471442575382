import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListDropDown from "components/inputfields/ListDropDown";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import { approvalDietFeed } from '../../../constants-ts';

import IProduct from "models/product";
interface IFeedApprovalFieldsDataProps {
  approve: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
};

const labelStyles = {
  margin: 0,
}

const FeedApprovalFields = ({ approve, state, setState}: IFeedApprovalFieldsDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%'}}>
      <InputLabel
        heading="Godkännandenummer ansvarig för märkning"
        text="Om personen som ansvarar för märkningen har flera godkännandenummer ska det nummer som tilldelats i enlighet med förordning (EG) nr 183/2005 användas."
        required
      />
      <InputTextField
        state={state}
        setState={setState}
        inputName="responsibleLabelingApprovalNumber"
        value={state.responsibleLabelingApprovalNumber}
        placeholder="Godkännandenummer för den person som ansvarar för märkningen"
        onChange={handleChange}
        approve={approve && !!state?.approve?.find(x => x.field === 'responsibleLabelingApprovalNumber')}
      />
      <FormControl component="fieldset">
        <InputLabel 
          heading="Är foder/fodertillskott framställt i enlighet med HACCP?"
          text="HACCP är ett verktyg för att på ett systematiskt sätt säkerställa att foder eller livsmedel ej medför oacceptabla risker för djur eller människors hälsa."
          link="https://www.sva.se/amnesomraden/sakert-foder-och-vatten/fabrikstillverkning-av-foder/haccp-systemet-for-faroanalys-och-kritiska-styrpunkter/"
          labelStyles={labelStyles}
        />
        <RadioGroup
          value={state?.complianceHaccp}
          name="complianceHaccp"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
      <InputLabel
        heading="Är produkten godkänt som dietfoder?"
        text="Om produkten är ett dietfoder, finns det ett godkännande för som visar hur den aktuella fodersammansättningen fyller det avsedda näringsbehovet samt att den inte har någon negativ inverkan på djurhälsa, människors hälsa eller djurskydd (art. 10 i förordning EG nr 767/2009) Försäljning och användning av dietfoder får endast ske om sammansättningen är sådan att den är lämplig för det särskilda näringsbehov."
        link="https://eur-lex.europa.eu/LexUriServ/LexUriServ.do?uri=OJ:L:2009:229:0001:0028:SV:PDF"
      />
      <ListDropDown
        disableLabel={true}
        listData={approvalDietFeed}
        value={state?.approvalDietFeed}
        targetName="approvalDietFeed"
        handleChange={handleDropdownChange}
        displayEmpty={true}
      />
    </div>
  );
}

export default FeedApprovalFields;
