import React, { FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { uniqBy } from 'ramda';

import { makeStyles, Link, CircularProgress, Chip } from '@material-ui/core';
import Button from 'components/button';
import LightTooltip from 'components/lightTooltip';
import TruncateText from 'components/TruncateText';
import ImageIcon from 'components/icons/ImageIcon';
import AddIcon from 'components/icons/AddIcon';
import ErrorInfoIcon from '../../../components/ErrorInfoIcon';
import ReviewTableHeaderCell from '../ReviewTableHeaderCell';

import useControlContext from '../../../hooks/useControlContext';
import useDataContext from '../../../hooks/useDataContext';

import { ALLOWED_IMAGE_FILE_TYPE_LIST } from '../../../constants';
import { IImageFileSource, IProductExcel } from '../../../types';
import { setWidthHeight } from 'utils/imgix';
import { STATUS_COLORS } from '../../../constants';
import { filterAllowedImages, sortImagesByName } from '../../../utils';

import { useStyles } from '../index.style';

const ICON_WIDTH = 24;
const IMAGE_WIDTH = 100;
const TOOLTIP_IMAGE_WIDTH = 250;
const IMAGE_GAP = 8;

const useCustomStyles = makeStyles(() => ({
  bodyImageCellWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: IMAGE_GAP,
  },
  bodyImageCell: {
    display: 'block',
    maxWidth: IMAGE_WIDTH,
    maxHeight: IMAGE_WIDTH,
  },
  bodyImageCellLarge: {
    display: 'block',
    maxWidth: TOOLTIP_IMAGE_WIDTH,
    maxHeight: TOOLTIP_IMAGE_WIDTH,
  },
  tooltipImageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imageInfoWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: IMAGE_GAP,
  },
  imageIconChip: {
    cursor: 'pointer',
    borderRadius: 3,
    height: 24,
    width: 24,
    background: '#9CA3BC',
    '& span': {
      padding: '0 4px',
      color: '#FFFFFF',
      fontWeight: 600,
    },
  },
}));

const ImportImageButton = () => {
  const { onProductImageChange } = useDataContext();

  const handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const acceptedImages = filterAllowedImages(Array.from(event.target.files || []));
    onProductImageChange(acceptedImages);
  };

  return (
    <LightTooltip placement="top" title="Bildnamn måste börja med antingen EAN-kod eller artikelnummer för att automatiskt matchas till produkterna">
      <div>
        <Button variant="contained" component="label" style={{ width: '100%' }}>
          <input
            hidden
            type="file"
            multiple
            accept={ALLOWED_IMAGE_FILE_TYPE_LIST.join(', ')}
            onChange={handleUploadFiles}
          />
          <span>Lägg till bilder</span>
        </Button>
      </div>
    </LightTooltip>
  );
};

interface ImageHeaderCellProps {
  isReview: boolean;
  isFakeCell?: boolean;
  dataList: IProductExcel[];
}

export const ImageHeaderCell: FC<ImageHeaderCellProps> = ({ isReview, isFakeCell, dataList }) => {
  const { productImageDataSet } = useDataContext();

  const isError = useMemo(
    () => dataList.some(({ id = '', image_src }) => !image_src && !productImageDataSet[id]),
    [dataList, productImageDataSet]
  );

  return (
    <ReviewTableHeaderCell
      title={isReview ? 'Bilder' : <ImportImageButton />}
      id="image"
      field="image"
      minWidth={275}
      isFieldUpdatable={false}
      isReview={isReview}
      isFakeCell={isFakeCell}
      isError={isError}
      showField={false}
      dataType="string"
    />
  );
};

interface ImageCellContentProps {
  isReview: boolean;
  width: number;
  data: IProductExcel;
  imageFileDataList: IImageFileSource[];
}

const ImageCellContent: FC<ImageCellContentProps> = ({
  isReview,
  width,
  data,
  imageFileDataList: imageFileDataListProp,
}) => {
  const classes = useStyles();
  const customClasses = useCustomStyles();
  const { uploadProgress } = useControlContext();

  const imageFileDataList = useMemo(() => {
    const localImageFileList = imageFileDataListProp.filter(({ url }) => !url);
    const uploadedImageFileList = imageFileDataListProp.filter(({ url }) => !!url);

    return [...localImageFileList, ...uniqBy(item => item.url?.split('?')?.[0], uploadedImageFileList)];
  }, [imageFileDataListProp]);

  const isUploadedBefore = useMemo(() => {
    return imageFileDataList.every(
      imageData => !!imageData.url && !imageData.uploadError && !imageData.generateAiError && !imageData.uploadAiError
    );
  }, [imageFileDataList]);

  const failedList = useMemo(() => {
    return imageFileDataList.filter(({ uploadError }) => uploadError);
  }, [imageFileDataList]);

  const isUploaded = useMemo(() => {
    return !!uploadProgress.uploaded.find(productId => productId === data.id);
  }, [data.id, uploadProgress.uploaded]);

  const isUploading = useMemo(() => {
    return uploadProgress.uploading.length && !isUploaded && !isUploadedBefore;
  }, [isUploaded, isUploadedBefore, uploadProgress.uploading.length]);

  const limitDisplay = useMemo(() => {
    let result = 0;
    const numberOfImages = imageFileDataList.length;
    const imageGap = numberOfImages > 1 ? IMAGE_GAP : 0; // flex-gap
    let spaceForImagesLeft = Math.max(width - ICON_WIDTH, 0); // AddIcon

    imageFileDataList.forEach(({ id }) => {
      const failedImage = failedList.find(failedImage => failedImage.id === id);
      const imageWidth = failedImage ? ICON_WIDTH : IMAGE_WIDTH;
      if (spaceForImagesLeft > imageWidth + imageGap) {
        spaceForImagesLeft -= imageWidth + imageGap;
        result += 1;
      }
    });

    // enought space for all images and icon
    if (result >= numberOfImages) return result;
    // left space is enought for Count icon
    if (spaceForImagesLeft > ICON_WIDTH + IMAGE_GAP) return result;
    // left space is NOT enought for Count icon
    return Math.max(result - 1, 0);
  }, [width, failedList, imageFileDataList]);

  if (!imageFileDataList.length) {
    return (
      <div className={clsx(classes.bodyCellBox, customClasses.bodyImageCellWrapper)}>
        {!isReview && <AddIcon style={{ margin: -4 }} />}
        {!isReview && <ErrorInfoIcon errors={[{ label: 'Missing image' }]} />}
      </div>
    );
  }

  if (isReview) {
    return (
      <div className={classes.bodyCellBox}>
        {imageFileDataList.map(imageFileData => (
          <div key={imageFileData.id}>
            <Link href={imageFileData.url} target="_blank">
              <TruncateText title={imageFileData.url || ''} />
            </Link>
          </div>
        ))}
      </div>
    );
  }

  if (isUploading) {
    return (
      <div className={classes.bodyCellBox} style={{ textAlign: 'center' }}>
        <CircularProgress size={19} />
      </div>
    );
  }

  const uploadErrorMessageList = uniqBy(
    item => item.message,
    imageFileDataList.flatMap(({ id }) => {
      const failedImage = failedList.find(failedImage => failedImage.id === id);
      if (!failedImage) return [];
      return [{ label: '', message: `Reason: ${failedImage.uploadError}` }];
    })
  );

  return (
    <div className={classes.bodyCellBox}>
      <div className={customClasses.bodyImageCellWrapper}>
        <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
          {imageFileDataList.slice(0, limitDisplay).map(({ id, url, source, fileName }) => {
            const failedImage = failedList.find(failedImage => failedImage.id === id);

            if (failedImage)
              return (
                <ErrorInfoIcon
                  key={id}
                  customIcon={<ImageIcon isError={!!failedImage} style={{ display: 'block', margin: -4 }} />}
                  errors={[{ label: failedImage.fileName, message: failedImage.uploadError }]}
                />
              );

            const tooltipTitle = (
              <div className={customClasses.tooltipImageWrapper}>
                <img
                  className={customClasses.bodyImageCellLarge}
                  src={url ? setWidthHeight(url, TOOLTIP_IMAGE_WIDTH, TOOLTIP_IMAGE_WIDTH) : source}
                  alt=""
                />
                <p style={{ textAlign: 'center' }}>{fileName}</p>
              </div>
            );

            return (
              <LightTooltip key={id} placement="top" title={tooltipTitle}>
                <div>
                  <img
                    className={customClasses.bodyImageCell}
                    src={url ? setWidthHeight(url, IMAGE_WIDTH, IMAGE_WIDTH) : source}
                    alt=""
                  />
                </div>
              </LightTooltip>
            );
          })}

          {!!imageFileDataList.slice(limitDisplay).length && (
            <Chip className={customClasses.imageIconChip} label={`+${imageFileDataList.slice(limitDisplay).length}`} />
          )}

          <AddIcon style={{ margin: -4 }} />
        </div>

        {!!uploadErrorMessageList.length && <ErrorInfoIcon type="warn" errors={uploadErrorMessageList} />}
      </div>
    </div>
  );
};

interface ImageCellProps extends Omit<ImageCellContentProps, 'width' | 'imageFileDataList'> {
  onClick: (id: string) => void;
}

const ImageCell: FC<ImageCellProps> = ({ isReview, data, onClick }) => {
  const classes = useStyles();
  const { productImageDataSet } = useDataContext();

  const [width, setWidth] = useState(0);
  const [wrapperElement, setWrapperElement] = useState<HTMLElement | null>(null);

  const imageFileDataList = useMemo(() => {
    return sortImagesByName(productImageDataSet[String(data.id || '')] || []);
  }, [data.id, productImageDataSet]);

  useEffect(() => {
    if (!wrapperElement) return;
    const observer = new ResizeObserver(() => setWidth(wrapperElement.offsetWidth));
    observer.observe(wrapperElement);
    return () => observer.disconnect();
  }, [wrapperElement]);

  const isError = !imageFileDataList.length;

  return (
    <td className={classes.bodyCell} style={{ background: isReview && isError ? STATUS_COLORS.ERROR : '' }}>
      <div
        ref={setWrapperElement}
        data-field="image"
        className={classes.bodyCellImport}
        onClick={() => onClick(String(data.id || ''))}
      >
        <ImageCellContent isReview={isReview} data={data} width={width} imageFileDataList={imageFileDataList} />
      </div>
    </td>
  );
};

const ImageColumn = {
  HeaderCell: ImageHeaderCell,
  BodyCell: ImageCell,
};

export default ImageColumn;
