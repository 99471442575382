import { FC, useEffect, useState, useContext, useMemo } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { Box, IconButton, Dialog, DialogContent } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LightTooltip from 'components/lightTooltip';
import Button from 'components/button';
import ConfirmModal from 'components/ConfirmModal';

import { KeycloakContext } from 'components/Secured';
import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

import { ALLOWED_IMAGE_FILE_TYPE_LIST } from '../constants';
import { IImageFileSource, IProductExcel } from '../types';
import { setWidthHeight } from 'utils/imgix';
import { parseImageFile } from '../utils';

import { useDropzoneStyles } from '../styles';

const CardWrapper = styled.div<{ isDraggingOver: boolean }>`
  border-radius: 3px;
  padding: 2px;
  overflow: auto;
  background: #edeef3;
  min-width: 258px;
  height: calc(100vh - 160px);
`;

const Card = styled.div<{ isDragging: boolean; isSelected: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  padding: 3px;
  border-radius: 2px;
  border-width: 1px;
  border-style: solid;
  color: #545d7d;
  border-color: ${props => (props.isSelected ? '#56ABA9' : 'transparent')};
  background: ${props => (props.isDragging ? 'rgba(173, 213, 255, 0.98)' : 'white')};
`;

const CardImage = styled.img`
  display: block;
  border-radius: 2px;
  max-width: 100px;
  max-height: 100px;
`;

const CardImageName = styled.p`
  margin: 0;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

const MainImage = styled.img`
  display: block;
  border-radius: 2px;
  max-width: calc(100vw - 400px);
  max-height: calc(100vh - 200px);
`;

interface EditProductImageModalProps {
  open: boolean;
  data: IProductExcel;
  onClose: () => void;
}

const EditProductImageModal: FC<EditProductImageModalProps> = ({ open, data, onClose }) => {
  const dropzoneClasses = useDropzoneStyles();
  const { keycloak } = useContext(KeycloakContext);
  const { setConvertingImages } = useControlContext();
  const { productImageDataSet, allImageFileDataList, onAllImageFileDataChange } = useDataContext();

  const [imageDataList, setImageDataList] = useState<IImageFileSource[]>([]);
  const [removingImageData, setRemovingImageData] = useState<IImageFileSource | null>(null);
  const [selectingImageData, setSelectingImageData] = useState<IImageFileSource | null>(null);

  const productId = useMemo(() => data.id || '', [data.id]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ALLOWED_IMAGE_FILE_TYPE_LIST.join(', '),
    onDrop: async (files: File[]) => {
      const promises = files.map(async file =>
        parseImageFile({
          file,
          token: keycloak?.token || '',
          startCallback: () => setConvertingImages(true),
        })
      );
      const newImageDataList = await Promise.all(promises);
      setConvertingImages(false);
      setImageDataList([...imageDataList, ...newImageDataList]);
    },
  });

  const handleSave = () => {
    const oldImageIdList = (productImageDataSet[productId] || []).map(({ id }) => id);
    const editedImageDataList = imageDataList.map(imageData => ({ ...imageData, assignProductId: productId }));
    const filteredImageFileDataList = allImageFileDataList.filter(({ id }) => !oldImageIdList.includes(id));
    const newImageFileDataList = [...filteredImageFileDataList, ...editedImageDataList];
    onAllImageFileDataChange(() => newImageFileDataList);
    onClose();
  };

  const handleDeleteImage = () => {
    if (!removingImageData) return;
    const newImageDataList = imageDataList.filter(({ id }) => id !== removingImageData.id);
    if (removingImageData.id === selectingImageData?.id) {
      setSelectingImageData(newImageDataList[0] || null);
    }
    setImageDataList(newImageDataList);
    setRemovingImageData(null);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const orderedList = Array.from(imageDataList);
    const [removed] = orderedList.splice(result.source.index, 1);
    orderedList.splice(result.destination.index, 0, removed);
    setImageDataList(orderedList);
  };

  useEffect(() => {
    setImageDataList(productImageDataSet[productId] || []);
  }, [productId, productImageDataSet]);

  useEffect(() => {
    if (!imageDataList.length || selectingImageData) return;
    setSelectingImageData(imageDataList[0]);
  }, [imageDataList, selectingImageData]);

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <DialogContent>
        <Box display="flex" flexDirection="column" gridGap={16} height="100%">
          <Box flexGrow={0} flexShrink={0}>
            <Box {...getRootProps({ className: dropzoneClasses.dropzone })} py={2}>
              <input {...getInputProps()} />
              <Box className={dropzoneClasses.title}>
                Drag images in this area or click to select from your computer!
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} display="flex" gridGap={16}>
            <Box flexGrow={0} flexShrink={0}>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <CardWrapper
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                      {...provided.droppableProps}
                    >
                      {imageDataList.map((imageData, index) => (
                        <Draggable key={imageData.id} draggableId={imageData.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ ...provided.draggableProps.style, padding: 2 }}
                              onClick={() => setSelectingImageData(imageData)}
                            >
                              <Card
                                isDragging={snapshot.isDragging}
                                isSelected={selectingImageData?.id === imageData.id}
                              >
                                <Box flexGrow={1} display="flex" justifyContent="center">
                                  <CardImage
                                    src={
                                      imageData.url
                                        ? setWidthHeight(imageData.url, 100, 100)
                                        : imageData.source
                                    }
                                    alt=""
                                  />
                                </Box>
                                <LightTooltip title={imageData.fileName} placement="top">
                                  <CardImageName>{imageData.fileName}</CardImageName>
                                </LightTooltip>
                                <IconButton
                                  size="small"
                                  color="secondary"
                                  onClick={event => {
                                    event.stopPropagation();
                                    setRemovingImageData(imageData);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Card>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </CardWrapper>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>

            {!!selectingImageData && (
              <Box flexGrow={1} display="flex" justifyContent="center" alignItems="center">
                <MainImage src={selectingImageData.url || selectingImageData.source} alt="" />
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>

      <Box display="flex" alignItems="center" justifyContent="end" gridGap={16} px={3} py={2}>
        <Button variant="outlined" size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" size="small" onClick={handleSave}>
          Save
        </Button>
      </Box>

      <ConfirmModal
        open={!!removingImageData}
        content={`Do you want to delete image ${removingImageData?.fileName}?`}
        onCancel={() => setRemovingImageData(null)}
        onOk={handleDeleteImage}
      />
    </Dialog>
  );
};

export default EditProductImageModal;
