import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IProduct from "models/product";

interface ICmrFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
};

const labelStyles = {
  margin: 0,
}

const CmrFields = ({ state, setState}: ICmrFieldsDataProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%'}}>
      <FormControl component="fieldset">
        <InputLabel
          heading="Innehåller produkten CMR-klassade ämnen?"
          text="Cancerframkallande, mutagena eller reproduktionsstörande kemiska ämnen kallas här CMR-ämnen. Det saknas ofta möjlighet att fastställa gränser för en säker exponering. För att bedöma om ett ämne är farligt kan du söka i PRIO:s databas."
          link="https://www.kemi.se/prioguiden/sok?hitsPerPage=20&tab=advancedSearch&UseAndOrOr=0&SortDirection=0&lastSearchType=advanced&CriteriaIDs=%5B975%2C960%2C961%2C962%2C981%5D&PriorityLevelIds=%5B%5D&MatchCriteria=1&SubstanceGroupIDs=%5B%5D&MatchSubstanceGroups=1&ProductTypeIDs=%5B%5D&MatchProductTypes=1&ReferenceIDs=%5B%5D&MatchReferences=1&MaterialIDs=%5B%5D&MatchMaterials=1&OrderBy=PriorityLevel_Id&Size=20&Paged=true"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.cmrSubstance}
          name="cmrSubstance"
          onChange={handleRadioChange}
          style={{ flexDirection: "row", gridGap: 16 }}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary"/>}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
      {state?.cmrSubstance && (
        <>
          <InputLabel
            heading="CMR-klassat ämne"
            text="Cancerframkallande, mutagena eller reproduktionsstörande kemiska ämnen kallas här CMR-ämnen. För att bedöma om ett ämne är farligt kan du söka i PRIO:s databas."
            link="https://www.kemi.se/prioguiden/sok?hitsPerPage=20&tab=advancedSearch&UseAndOrOr=0&SortDirection=0&lastSearchType=advanced&CriteriaIDs=%5B975%2C960%2C961%2C962%2C981%5D&PriorityLevelIds=%5B%5D&MatchCriteria=1&SubstanceGroupIDs=%5B%5D&MatchSubstanceGroups=1&ProductTypeIDs=%5B%5D&MatchProductTypes=1&ReferenceIDs=%5B%5D&MatchReferences=1&MaterialIDs=%5B%5D&MatchMaterials=1&OrderBy=PriorityLevel_Id&Size=20&Paged=true"
            required
          />
          <InputTextField
            state={state}
            setState={setState}
            inputName="CMRSubstances"
            value={state.CMRSubstances}
            placeholder="Cobalt Metaldehyde (ISO); 2,4,6,8-tetramethyl- 1. Lista separerad med komma"
            onChange={handleChange}
          />
        </>
      )}
    </div>
  );
}

export default CmrFields;
