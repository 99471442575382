import React, { useContext } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { TotalProducers, TotalProducts, UnpaidInvoices } from '../../components/dashboard';
import { SuperuserStatistics } from '../../components/superuser';
import { KeycloakContext } from '../../components/Secured';
import { getUserRole, isAdmin, isSuperuser } from '../../utils/userrole';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Block } from '@material-ui/icons';
import { useEffect } from 'react';
import { blue } from '@material-ui/core/colors';
import { IS_FOOD_LOCAL_STORAGE_KEY } from '../../constants/domain';
import { parseBoolean } from '../../utils/helpers';

const Dashboard = () => {
    const keycloakCtx = useContext(KeycloakContext);
    const username = keycloakCtx?.keycloak?.tokenParsed?.preferred_username;
    // const showNonFood = username === 'nonfoodadmin' || username === 'coopnonfood' || username === 'sarakols';
    const showNonFood = true;
    let userResourceRole = getUserRole(keycloakCtx);
    const superuser = isSuperuser(userResourceRole);
    const admin = isAdmin(userResourceRole);

    if (admin && localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY) == null) {
        localStorage.setItem(IS_FOOD_LOCAL_STORAGE_KEY, "true");
    } else if (!admin && localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY) != null) {
        localStorage.removeItem(IS_FOOD_LOCAL_STORAGE_KEY);
    }
    
    const handleToggle = (e, value) => {
        if(value) {
            setIsFood(value);
        }
    };

    const initIsFood = showNonFood ? localStorage.getItem("isFood") : 'true';
    const [isFood, setIsFood] = React.useState(initIsFood);

    useEffect(() => {
        if(admin) {
            localStorage.setItem(IS_FOOD_LOCAL_STORAGE_KEY, isFood);
        }
    }, [isFood]);

    const styleSelectedButton = { borderColor: 'blue', borderWidth: '0.15em', borderRadius: '4px' };

    return (
        <>
            <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid container item xs={12} spacing={1}>
                      <UnpaidInvoices />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={6}>
                        <TotalProducers isFood={parseBoolean(isFood)} showAll={superuser} />
                    </Grid>
                    <Grid item xs={6}>
                        <TotalProducts isFood={parseBoolean(isFood)} showAll={superuser} />
                    </Grid>
                </Grid>
                {superuser && <SuperuserStatistics />}
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <div>
                                    <h2>Foodla Support</h2>
                                    <p>
                                        <span>Contact: +(46) 70 555 44 18</span>
                                    </p>
                                    <p>
                                        <span>Email: support@foodla.nu</span>
                                    </p>
                                    <p>
                                        <span>Website: http://foodla.nu</span>
                                    </p>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                    { showNonFood && admin &&
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <p style={{ width: '60%' }}>
                                            { isFood == "true" &&
                                                <span>
                                                    This account is set to only show food producers and food products. You can switch the account
                                                    to only show non-food producers and non-food products by shifting the toggle button
                                                </span>
                                            }
                                            { isFood == "false" &&
                                                <span>
                                                    This account is set to only show non-food producers and non-food products. You can switch the account
                                                    to only show food producers and food products by shifting the toggle button
                                                </span>
                                            }
                                        </p>
                                        <ToggleButtonGroup
                                            value={isFood}
                                            onChange={handleToggle}
                                            exclusive
                                            style={{ height: '2rem' }}
                                        >
                                            <ToggleButton value="true" selected={parseBoolean(isFood)} style={parseBoolean(isFood) ? styleSelectedButton : {}}>
                                                Food
                                            </ToggleButton>
                                            <ToggleButton value="false" selected={!parseBoolean(isFood)} style={parseBoolean(isFood) ? {} : styleSelectedButton}>
                                                Non-Food
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default Dashboard;