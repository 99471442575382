import React, { useEffect, useMemo, useState } from 'react';

import { map } from 'ramda';
import _ from 'lodash';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import DetailedInputLabel from '../../../inputfields/InputLabel';
import InputLabel from "../../../inputfields/InputLabel";
import ListDropDown from '../../../inputfields/ListDropDown';
import { PRODUCT_CLASSIFICATIONS, getPercentageValues, APPLICATION_ROLES, RECYCLING_AMOUNT } from '../../../constants';
import { requiresClassification, optionalAlcoholPercentage, PRODUCT_CATEGORY_ROOT } from 'components/constants-ts';
import ThreeWaySwitch from '../../../inputfields/ThreeWaySwitch';
import IProduct, { IFoodlaCategory, IFullFoodlaCategoryTree } from '../../../../models/product';
import { IFoodlaCategoryOption } from 'models/category';

import {
  eggWeightClass,
  recyclingType
} from '../../../constants-ts';
import useCategoriesData from 'hooks/useCategoriesData';
import CategoryTree, { findCategory } from './category-tree';

interface ICategoryProps {
  isUserStore: boolean;
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
  userRole?: string;
  rootCategory?: IFoodlaCategoryOption;
  categoryLoading?: boolean;
  categoryError?: string;
}

export const getFullCategoryTree = (category: IFoodlaCategory, rootCategory: IFoodlaCategoryOption) => {
  let fullCategoryTree: IFullFoodlaCategoryTree = {
    id: category.id,
    name: category.name,
  };

  const findParent = (currentCategory: IFoodlaCategory, rootCategory: IFoodlaCategoryOption) => {
    if (rootCategory.children) {
      for (const child of rootCategory.children) {
        if (child.id === currentCategory.id) {
          return true;
        }
        if (findParent(currentCategory, child)) {
          fullCategoryTree = {
            id: child.id,
            name: child.name,
            child: fullCategoryTree,
          };
          return true;
        }
      }
    }
    return null;
  };
  findParent(category, rootCategory);

  return fullCategoryTree;
};

export const getParentCategories = (category: IFoodlaCategory, rootCategory: IFoodlaCategoryOption) => {
  const parent: string[] = [];
  const findParent = (currentCategory: IFoodlaCategory, rootCategory: IFoodlaCategoryOption) => {
    if (rootCategory.children) {
      for (const child of rootCategory.children) {
        if (child.id === currentCategory.id) {
          parent.push(child.name);
          return true;
        }
        if (findParent(currentCategory, child)) {
          parent.push(child.name);
          return true;
        }
      }
    }
    return false;
  };
  findParent(category, rootCategory);
  return parent;
};

const isCategory  = (category: string, currentCategory?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
    if (!currentCategory || !rootCategory) return false;
    const parent = getParentCategories(currentCategory, rootCategory);
    
    return parent.includes(category) || currentCategory.name === category;
};

export const isEgg = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Ägg och äggprodukter', category, rootCategory);
};

export const isFruktOrGreen = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Frukter, bär och grönsaker', category, rootCategory);
};

export const isBeverages = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Drycker', category, rootCategory);
};

export const isFish = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption, state?: IProduct) => {
  return (
    isCategory('Fisk och fiskeriprodukter', category, rootCategory) ||
    (state?.producerIsStore && isCategory('Egen fiskdisk', category, rootCategory))
  );
};

export const isFoodSupplements = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Kosttillskott enligt definitionen i direktiv 2002/46/EG', category, rootCategory);
};

export const isNewFood = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Food', category, rootCategory);
};

export const isNewNonFood = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Non food', category, rootCategory);
};

export const isToy = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Leksak', category, rootCategory);
};

export const isElectric = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Elektrisk', category, rootCategory);
};

export const isJewelry = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Smycken, kläder, glasögon, accessoarer', category, rootCategory);
};

export const isGoods = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Varor', category, rootCategory);
};

export const isMedical = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Medicinteknik', category, rootCategory);
};

export const isChemical = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Kemiska produkter', category, rootCategory);
};

export const isDetergent = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Tvättmedel/Diskmedel', category, rootCategory);
};

export const isCosmetic = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Kosmetiska produkter', category, rootCategory);
};

export const isComplementarySupplements = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Kompletteringsfoder & Fodertillskott', category, rootCategory);
};

export const isSunscreen = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Solskyddsmedel', category, rootCategory);
};

export const isFeedMaterial = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Foderråvara', category, rootCategory);
};

export const isFeedMixtures = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Foderblandningar', category, rootCategory);
};

export const isCompleteFeed = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Helfoder', category, rootCategory);
};

export const isOTC = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Receptfria läkemedel', category, rootCategory);
};

export const isOtherFoodstuffs = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Övrigt livsmedel', category, rootCategory);
};

export const isSnusOrTobacco = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  // TODO: this category in food
  return isCategory('Övrigt livsmedel/Snus & tobak', category, rootCategory);
};

export const isSpecialFoodNutrition = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Livsmedel för särskilda näringsändamål', category, rootCategory);
};

export const isSpicesSoupsSalads = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Salt, kryddor, soppor, såser, sallader och proteinprodukter', category, rootCategory);
};

export const isMeat = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Kött', category, rootCategory);
};

export const isBakeryProducts = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Bageriprodukter', category, rootCategory);
};

export const isMilk = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Mjölkprodukter och motsvarande icke mjölkbaserade produkter', category, rootCategory);
};

export const isCereals = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Spannmål och spannmålsprodukter', category, rootCategory);
};

export const isConfectionery = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Konfektyrvaror', category, rootCategory);
};

export const isConsumptionReadySnacks = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Konsumtionsfärdiga snacks', category, rootCategory);
};

export const isSweet = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Sockerarter, olika former av sirap, honung och bordssötningsmedel', category, rootCategory);
};

export const isFats = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Fetter och oljor samt fett- och oljeemulsioner', category, rootCategory);
};

export const isIceCream = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Glassvaror', category, rootCategory);
};

export const isMagazine = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return isCategory('Tidningar', category, rootCategory);
};

export const isFeedCategory = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return (
    isComplementarySupplements(category, rootCategory) ||
    isFeedMaterial(category, rootCategory) ||
    isFeedMixtures(category, rootCategory) ||
    isCompleteFeed(category, rootCategory)
  )
};

export const isCmrHazardCategory = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return (
    isGoods(category, rootCategory) ||
    isJewelry(category, rootCategory) ||
    isToy(category, rootCategory) ||
    isElectric(category, rootCategory) ||
    isDetergent(category, rootCategory)
  )
};

export const isOtherFoodGroup = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return (
    isBakeryProducts(category, rootCategory) ||
    isMilk(category, rootCategory) ||
    isCereals(category, rootCategory) ||
    isConfectionery(category, rootCategory) ||
    isConsumptionReadySnacks(category, rootCategory) ||
    isSweet(category, rootCategory) ||
    isFats(category, rootCategory) ||
    isIceCream(category, rootCategory)
  )
};

export const isPharma = (category?: IFoodlaCategory, rootCategory?: IFoodlaCategoryOption) => {
  return (
    isChemical(category, rootCategory) ||
    isMedical(category, rootCategory) ||
    isCosmetic(category, rootCategory) ||
    isSunscreen(category, rootCategory) ||
    isOTC(category, rootCategory)
  )
};

const isInCategory = (currentCategory?: IFoodlaCategory, category?: string, rootCategory?: IFoodlaCategoryOption) => {
  if (
    !currentCategory || !!currentCategory.name || currentCategory.name.trim() === ''
    || !category || category.trim() === ''
    || !rootCategory
  ) {
    return false;
  }
  if (currentCategory.name === category) {
    return true;
  }
  if (rootCategory.children) {
    for (const child of rootCategory.children) {
      if (isInCategory(child, category, currentCategory)) {
        return true;
      }
    }
  }
  return false;
};

type TApproveFieldConstant = {
  field: keyof IProduct | 'images' | 'nettokvantitet';
  changeFields?: string[];
  conditionFunc: (product: IProduct, rootCategory?: IFoodlaCategoryOption) => boolean | undefined;
}

const APPROVE_FIELDS_ALL: TApproveFieldConstant[] = [
  {
    field: 'title',
    conditionFunc: () => true,
  },
  {
    field: 'foodlaCategory',
    conditionFunc: () => true,
  },
  {
    field: 'images',
    conditionFunc: () => true,
  },
  {
    field: 'EAN',
    conditionFunc: () => true,
  },
  {
    field: 'short_text',
    conditionFunc: () => true,
  },
  {
    field: 'labels',
    conditionFunc: () => true,
  },
  {
    field: 'nettokvantitet',
    changeFields: ['descriptive_size_amount', 'descriptive_size_unit'],
    conditionFunc: () => true,
  },
  {
    field: 'trade_item_temperature_information',
    conditionFunc: () => true,
  },
  {
    field: 'gross_weight_num',
    conditionFunc: () => true,
  },
  {
    field: 'gross_height',
    changeFields: ['gross_height', 'gross_width', 'gross_depth'],
    conditionFunc: () => true,
  },
];

const APPROVE_FIELDS_FOOD: TApproveFieldConstant[] = [
  {
    field: 'alcoholPercentage',
    conditionFunc: (product, rootCategory) => {
      return isBeverages(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'recyclingType',
    conditionFunc: (product, rootCategory) => {
      return isBeverages(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'recycling_amount',
    conditionFunc: (product, rootCategory) => {
      return isBeverages(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'classification',
    conditionFunc: (product, rootCategory) => {
      return isFruktOrGreen(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'country_of_manufacturing',
    conditionFunc: (product, rootCategory) => {
      return !isEgg(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'dosageAndUsageInstructions',
    conditionFunc: (product, rootCategory) => {
      return (
        isSpecialFoodNutrition(product.foodlaCategory, rootCategory)
        || isFoodSupplements(product.foodlaCategory, rootCategory)
      );
    },
  },
  {
    field: 'preparedForm',
    conditionFunc: (product, rootCategory) => {
      return (
        isSpecialFoodNutrition(product.foodlaCategory, rootCategory)
        || isFoodSupplements(product.foodlaCategory, rootCategory)
      );
    },
  },
  {
    field: 'eggWeightClass',
    conditionFunc: (product, rootCategory) => {
      return isEgg(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'eggPackerApprovalNumber',
    conditionFunc: (product, rootCategory) => {
      return isEgg(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'fishProductionMethod',
    conditionFunc: (product, rootCategory) => {
      return isFish(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'fishCatchMethod',
    conditionFunc: (product, rootCategory) => {
      return isFish(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'catchArea',
    conditionFunc: (product, rootCategory) => {
      return isFish(product.foodlaCategory, rootCategory);
    },
  },
  ...APPROVE_FIELDS_ALL,
];

const APPROVE_FIELDS_NON_FOOD: TApproveFieldConstant[] = [
  {
    field: 'dosageAndUsageInstructions',
    conditionFunc: () => true,
  },
  {
    field: 'non_food_ingredients',
    conditionFunc: (product, rootCategory) => {
      return !isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'signalWords',
    conditionFunc: (product, rootCategory) => {
      return !isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'animalFoodIngredients',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'place_of_item_activity',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'responsibleLabelingApprovalNumber',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'animalCategoriesForFeed',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'feedAdditives',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'analyticalConstituentsFeed',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'feedingInstructions',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'animalAgeGroup',
    conditionFunc: (product, rootCategory) => {
      return isFeedCategory(product.foodlaCategory, rootCategory);
    },
  },
  {
    field: 'preparedForm',
    conditionFunc: (product, rootCategory) => {
      return isPharma(product.foodlaCategory, rootCategory)
      || isDetergent(product.foodlaCategory, rootCategory)
      || isComplementarySupplements(product.foodlaCategory, rootCategory);
    },
  },
  ...APPROVE_FIELDS_ALL,
];

export const isCategoryChanged = (oldProduct: IProduct, product: IProduct) => {
  const { id: oldFoodlaCategoryId } = oldProduct.foodlaCategory || { id: '' };
  const { id: foodlaCategoryId } = product.foodlaCategory || { id: '' };

  return oldFoodlaCategoryId !== foodlaCategoryId;
}

export const isParentCategoryChanged = (oldProduct?: IProduct, product?: IProduct, rootCategory?: IFoodlaCategoryOption) => {
  if (!rootCategory) return false;
  // both of old and new product do NOT have category -> not changed
  if (!oldProduct?.foodlaCategory && !product?.foodlaCategory) return false;
  // Next, one of them does NOT have category -> difference -> changed
  if (!oldProduct?.foodlaCategory || !product?.foodlaCategory) return true;

  const oldList = getParentCategories(oldProduct.foodlaCategory, rootCategory);
  oldList.reverse();

  const newList = getParentCategories(product.foodlaCategory, rootCategory);
  newList.reverse();

  return oldList[0] !== newList[0] || oldList[1] !== newList[1];
}

export const getNewApproveByCategory = (
  product: IProduct,
  rootCategory?: IFoodlaCategoryOption,
  options?: { ignoreMissingField?: boolean },
) => {
  const isFood = isNewFood(product.foodlaCategory, rootCategory);
  const isNonFood = isNewNonFood(product.foodlaCategory, rootCategory);

  let checkingFields = APPROVE_FIELDS_ALL;
  if (isFood) {
    checkingFields = APPROVE_FIELDS_FOOD;
  }
  if (isNonFood) {
    checkingFields = APPROVE_FIELDS_NON_FOOD;
  }

  let approve = _.cloneDeep(product.approve || []);
  approve = approve.map((item) => ({ ...item, state: null }));

  checkingFields.forEach(({ field, conditionFunc }) => {
    if (conditionFunc(product, rootCategory)) {
      if (!options?.ignoreMissingField) {
        const exsiting = approve.some((i) => i.field === field);
        // add missing fields
        if (!exsiting) {
          approve.push({ field, state: null });
        }
      }
    } else {
      // delete unnecessary fields
      approve = approve.filter((i) => i.field !== field);
    }
  });

  return approve;
}

export const removeUnnecessaryAprroveFields = (state: IProduct, rootCategory?: IFoodlaCategoryOption) => {
  let approveList = _.cloneDeep(state.approve || []);
  const approveForCurrentCategory = getNewApproveByCategory(state, rootCategory);
  // remove fields which do not for current category
  // Only check approve fields from "state.approve", which is for current category
  return approveList.filter((item) => {
    return approveForCurrentCategory.some(({ field }) => field === item.field);
  });
}

export const Category = ({
  userRole,
  state,
  setState,
  approve,
  isUserStore,
  rootCategory,
  categoryLoading,
  categoryError,
}: ICategoryProps) => {
  // const { loading, error, rootCategory } = useCategoriesData();

  const [savedRecyclingAmount] = useState(state.recycling_amount);
  const [productType, setProductType] = useState('');

  const treeCategory = useMemo(() => {
    const treeData = _.cloneDeep(rootCategory)?.children?.find(({ id }) => id === productType);
    
    if (productType === 'food' && treeData?.children?.length) {
      if (!state.producerIsStore && !isUserStore) {
        treeData.children = treeData.children.filter(({ name }) => name !== 'Butikens egna varor');
      }
      const storeGoods = treeData.children.find(({ name }) => name === 'Butikens egna varor');
      if (storeGoods) {
        const rest = treeData.children.filter(({ name }) => name !== 'Butikens egna varor');
        treeData.children = [storeGoods, ...rest];
      }
    }

    return treeData;
  }, [productType, isUserStore, rootCategory, state]);

  const hasApprove = (field: string) => {
    if (!approve) return false;
    if (!state?.approve) return false;
    if (state.approve.length === 0) return false;
    const approveItem = state.approve.find((x) => x.field === field);
    if (!approveItem) return false;
    return true;
  };

  const percentageValues = getPercentageValues();

  const handleChange = (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    const stateName = event.target.name;
    let stateValue = event.target.value;

    stateName && setState({ ...state, [stateName]: stateValue });
  };

  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      console.log(`NAME: ${stateName} - VALUE: ${stateValue}`);
      const val = !stateValue || stateValue === '' ? null : stateValue;

      if (!stateName) return;

      const newState = _.cloneDeep({ ...state, [stateName]: val });

      if (stateName === 'recyclingType') {
        if (stateValue === 'RECYCLABLE_PET') {
          newState.recycling_amount = 2;
        } else if (['ALUMINUM_CAN', 'RECYCLABLE_PET_UP_TO_1000'].includes(stateValue as string)) {
          newState.recycling_amount = 1;
        } else if (savedRecyclingAmount) {
          newState.recycling_amount = null as any;
        }
      }
      setState(newState);
  };

  const handleCategoryChange = (category: IFoodlaCategoryOption) => {
    setState({ ...state, foodlaCategory: category });
  }

  const categories = useMemo(() => {
    const productCategory = PRODUCT_CATEGORY_ROOT.children?.find(
      ({ id }) => id === productType
    );
    return productCategory?.children || [];
  }, [productType]);

  // detect product type
  useEffect(() => {
    if (productType || !rootCategory?.children?.length) return;
    let newProductType = rootCategory.children[0].id;
    rootCategory?.children?.forEach(productCategory => {
      if (!state.foodlaCategory?.id) return;
      if (findCategory(productCategory?.children || [], state.foodlaCategory.id)) {
        newProductType = productCategory.id;
      }
    });
    setProductType(newProductType);
  }, [state.foodlaCategory, productType, rootCategory]);

  return (
    <>
      <DetailedInputLabel
        id="foodlaCategory"
        heading={'Kategori'}
        required
        text={
          'Hör av dig till support@foodla.nu om du är osäker på vilken kategori du ska välja så hjälper vi dig!'
        }
        // link={
        //   'https://help.foodla.nu/knowledge/vilken-kategori-ska-jag-v%C3%A4lja-i-foodla'
        // }
      />
      <FormControl component="fieldset">
        <RadioGroup row value={productType} onChange={event => setProductType(event.target.value)}>
          {rootCategory?.children?.map(productCategory => (
            <FormControlLabel
              key={productCategory.id}
              value={productCategory.id}
              control={<Radio color="primary" />}
              label={productCategory.name}
              labelPlacement="start"
            />
          ))}
        </RadioGroup>
      </FormControl>
      <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
        <CategoryTree
          loading={!!categoryLoading}
          error={categoryError}
          selectedCategory={state.foodlaCategory}
          treeCategory={treeCategory}
          onSelect={(category) => {
            const { children, ...data } = category;
            handleCategoryChange(data);
          }}
        />
        {hasApprove("foodlaCategory") && (
          <ThreeWaySwitch
            state={state}
            style={{flex: 0.1, maxHeight: 56}}
            setState={setState}
            name="foodlaCategory"
          />
        )}
      </div>
      {isEgg(state?.foodlaCategory, rootCategory) && (
        <>
          <InputLabel
            heading="Viktklass (ägg)"
            text="Ägg av klass A bör klassificeras efter vikt. Det finns mer information i förordningen (EG) 1234/2007 om handelsnormer för ägg."
            link="https://www.svenskaagg.se/wp-content/uploads/2022/07/1960.pdf"
          />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginTop: 10 }}>
            <ListDropDown
              disableLabel={true}
              listData={eggWeightClass}
              value={state?.eggWeightClass}
              targetName="eggWeightClass"
              handleChange={handleDropdownChange}
              displayEmpty={true}
              style={{ flex: 0.9 }}
            />
            {hasApprove("eggWeightClass") && (
              <ThreeWaySwitch
                state={state}
                style={{ flex: 0.1, maxHeight: 56 }}
                setState={setState}
                name="eggWeightClass"
              />
            )}
          </div>
        </>
      )}
      {isFruktOrGreen(state?.foodlaCategory, rootCategory) && (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginTop: 10 }}>
          <ListDropDown
            label="Klassifiering"
            required
            listData={map(c => ({ value: c }), PRODUCT_CLASSIFICATIONS)}
            handleChange={handleChange}
            value={state.classification}
            targetName="classification"
            style={{ marginBottom: 15, flex: 0.9 }}
          />
          {hasApprove("classification") && (
            <ThreeWaySwitch
              state={state}
              style={{ flex: 0.1, maxHeight: 56 }}
              setState={setState}
              name="classification"
            />
          )}
        </div>
      )}
      {isBeverages(state?.foodlaCategory, rootCategory) && (
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginTop: 10 }}>
          <ListDropDown
            label="Alkoholhalt"
            listData={map(c => ({ value: c }), percentageValues)}
            handleChange={handleChange}
            value={state.alcoholPercentage}
            targetName="alcoholPercentage"
            style={{ flex: 0.9 }}
          />
          {hasApprove("alcoholPercentage") && (
            <ThreeWaySwitch
              state={state}
              style={{ flex: 0.1, maxHeight: 56 }}
              setState={setState}
              name="alcoholPercentage"
            />
          )}
        </div>
      )}
      {isBeverages(state?.foodlaCategory, rootCategory) && (
        <>
        <InputLabel
          heading="Panttyp"
          required
        />
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginTop: 10 }}>
          <ListDropDown
            disableLabel={true}
            listData={recyclingType}
            value={state?.recyclingType}
            targetName="recyclingType"
            handleChange={handleDropdownChange}
            displayEmpty={true}
            style={{ flexGrow: 1, overflow: 'hidden', paddingTop: 5 }}
          />
          {hasApprove("recyclingType") && (
            <ThreeWaySwitch
              state={state}
              setState={setState}
              name="recyclingType"
              style={{ marginTop: 5, maxHeight: 56 }}
            />
          )}
        </div>
      </>)}
      {isBeverages(state?.foodlaCategory, rootCategory) && (
        <>
          <InputLabel heading="Pantbelopp" />
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginTop: 10 }}>
            <ListDropDown
              fullWidth
              displayEmpty
              listData={RECYCLING_AMOUNT}
              handleChange={handleChange}
              value={state.recycling_amount}
              targetName="recycling_amount"
              style={{ flexGrow: 1, overflow: 'hidden', paddingTop: 5 }}
            />
            {hasApprove("recycling_amount") && (
              <ThreeWaySwitch
                state={state}
                setState={setState}
                name="recycling_amount"
                style={{ marginTop: 5, maxHeight: 56 }}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Category;
