import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uniqBy, isEmpty, path } from 'ramda';
// import { setImageDpi, setWidthHeight, setDpi } from '../../utils/imgix';

import InputLabel from '../inputfields/InputLabel';
import { KeycloakContext } from '../Secured';
import { dimensionsCheck, tiffToPng } from './ImageUploader2';

// TODO: Take styling as props to make it more dynamic.
const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

// const previewFile = async (file) => {
//   return new Promise((res, rej) => {
//     const reader = new FileReader();

//     reader.onloadend = () => {
//       res(reader.result);
//     };

//     if (file) {
//       reader.readAsDataURL(file);
//     } else {
//       res("");
//     }
//   });
// };

// const convertTiffToPng = async (file) => {
//   const fileSrc = URL.createObjectURL(file);
//   if (fileSrc) {
//     const { width, height, rgba } = await getTiffData(fileSrc);  
//     const canvas = document.createElement('canvas');
//     canvas.width = width;
//     canvas.height = height;
//     const ctx = canvas.getContext("2d");
//     const imgData = new ImageData(
//       new Uint8ClampedArray(rgba.buffer),
//       width,
//       height,
//     );
//     ctx?.putImageData(imgData, 0, 0);
//     // let url;
//     const blob = await new Promise(resolve => canvas.toBlob(resolve));
//     return blob;
//     // if (blob) {
//     //   url = URL.createObjectURL(blob);
//     // }
//     // console.log('!url', url);
//     // return url;
//   }

//   URL.revokeObjectURL(fileSrc);
//   return undefined;
// };

const ImageDropZone = ({
  containerStyle,
  onImageDropped,
  withLabel,
  labelOptions,
  multiple,
  withOutImagePreview,
  disabled, 
  withDimensionCheck,
  onImagesRejected,
  setLoadingTiff,
}) => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);
  
  const [thumbs, setThumbs] = useState([]);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    acceptedFiles: ["image/jpeg", "image/png", "image/tiff"],
    multiple,
    disabled,
    onDrop: async acceptedFiles => {
      let images = acceptedFiles;

      if (withDimensionCheck) {
        const { acceptedByDimension, rejectedByDimension } = await dimensionsCheck(acceptedFiles);
        console.log('!!!acceptedByDimension', acceptedByDimension);
        console.log('!!!rejectedByDimension', rejectedByDimension);
        if(!isEmpty(rejectedByDimension)) onImagesRejected(rejectedByDimension);
        if(isEmpty(acceptedByDimension)) return;
        images = acceptedByDimension;
      }

      // for (let i = 0; i < images.length; i++) {
      //   images[i] = await setDpi(images[i]);
      // }
      
      setLoadingTiff(true);
      images = await Promise.all(images.map(async (image) => await tiffToPng(image, token)));
      setLoadingTiff(false);
      images = images.map((image) => {
        const name = (+new Date()).toString() + '_' + image.name;
        const file = new File([image], name, {type: image.type});
        file.path = name;
        return file;
      });
      const allFiles = uniqBy(f => f.path, [...files, ...images]);
      const allThumbs = await Promise.all(allFiles.map(async (f) => {
          return URL.createObjectURL(f);
      }));
      // const allThumbs = await Promise.all(allFilesT.map((f) => previewFile(f)));

      console.log('!!!allFiles', allFiles);
      console.log('!!!allThumbs', allThumbs);
      setFiles(allFiles);
      setThumbs(allThumbs);
      onImageDropped(allFiles);
    },
  });

  const thumbsPictures = thumbs.map(t => (
    <div style={thumb} key={t}>
      <div style={thumbInner}>
        <img src={t} style={img} alt="preview" />
      </div>
    </div>
  ));

  React.useEffect(
    () => () => {
      thumbs.forEach(thumb => URL.revokeObjectURL(thumb));
    },
    [thumbs]
  );

  return (
    <section className="container" style={containerStyle}>
      {withLabel && <InputLabel { ...labelOptions } />}
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <span>Dra en bild till det här området eller klicka för att välja en från din dator!</span>
      </div>
      {!withOutImagePreview && <aside style={thumbsContainer}>{thumbsPictures}</aside>}
    </section>
  );
};

export default ImageDropZone;
