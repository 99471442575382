import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {path, concat} from 'ramda';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import {ListItemAvatar, Avatar} from '@material-ui/core';

import COLORS from '../../../../constants/colors';
import IProduct from '../../../../models/product';
import HighlightedText from '../../../../components/highlightedText/HighlightedText';

import { isDefined } from 'utils/helpers';
import { IMAGE_SIZES, getImageWithSizeSuffix } from 'utils/images2';
import { SUPERUSER_APPROVAL } from '../../../constants';
import { GreenBlinkingBadge, RedBlinkingBadge } from '../../../badges/badges';

interface IProductListItemProps {
    product: IProduct;
    showNotApproved?: boolean;
    searchString?: string;
    showEAN?: boolean;
}

interface IProductListViewProps {
    list: IProduct[];
    searchString?: string;
    showEAN?: boolean;
}

const ProductListItem = ({product, showNotApproved, searchString, showEAN}: IProductListItemProps) => {
    const producerStatus = path(['producer', 'status'], product);
    const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
    if (!showNotApproved && producerNotAccepted) return <></>;

    const primaryTitle = product?.title || 'NO TITLE';
    let secondaryTitle = product?.producer?.name || 'NO PRODUCER NAME';
    const linkTo = `/superuser/products/${product.id}/approval`;

    const image = product.image_src && isDefined(product.image_src) ? getImageWithSizeSuffix(product.image_src, IMAGE_SIZES.XSMALL) : '';
    if (producerNotAccepted) secondaryTitle = concat(secondaryTitle, ' (PRODUCER NOT ENABLED)');
    let ean = '';
    if (showEAN) {
        if (product.EAN && product.EAN.trim() !== '') {
            ean += product.EAN.trim();
        }
        if (product.EAN2 && product.EAN2.trim() !== '') {
            ean += ` (${product.EAN2.trim()})`;
        }
    }

    return (
        <>
            <Link variant="inherit" underline="none" color="inherit" component={RouterLink} to={linkTo}>
                <ListItem>
                    <ListItemAvatar>
                        {producerNotAccepted ? (
                            <RedBlinkingBadge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <Avatar>
                                    <img src={image} alt="P" />
                                </Avatar>
                            </RedBlinkingBadge>
                        ) : (
                            <GreenBlinkingBadge
                                overlap="circle"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                variant="dot"
                            >
                                <Avatar>
                                    <img src={image} alt="P" />
                                </Avatar>
                            </GreenBlinkingBadge>
                        )}
                    </ListItemAvatar>
                    <ListItemText
                        style={producerNotAccepted ? { color: COLORS.mainRed } : {}}
                        primary={<HighlightedText text={primaryTitle} searchString={searchString} />}
                        secondary={(
                            <>
                                <HighlightedText text={secondaryTitle} searchString={searchString} />
                                {showEAN && ean && ean.trim() !== '' && (
                                    <>
                                        <br />
                                        <HighlightedText text={ean} searchString={searchString} />
                                    </>
                                )}
                            </>
                        )}
                    />
                </ListItem>
            </Link>
            <Divider />
        </>
    );
};

const ProductListView = ({list, searchString, showEAN}: IProductListViewProps) => {
    if (!list.length) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <span
                    style={{ fontSize: 18, textAlign: 'center', marginTop: 20, color: COLORS.mainGreen, fontWeight: 'bold' }}
                >
                  No products with Failed Review. Nothing to do here! :)
                </span>
            </div>
        );
    }

    return (
        <List dense>
            {list && list.map((p: IProduct) => (
                <ProductListItem
                    product={p}
                    key={p.id}
                    showNotApproved={true}
                    searchString={searchString}
                    showEAN={showEAN}
                />
            ))}
        </List>
    );
};

export default ProductListView;
