import React, { useContext, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Footer from '../components/Footer';
import AuthMenu from './AuthMenu';
import ErrorBoundary from '../components/ErrorBoundary';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { QUERY_INIT_PRODUCER_CHECK } from '../graphql/queries';
import { KeycloakContext } from '../components/Secured';
import { extractURLParam } from '../utils/urlUtils';
import HelpMenu from './HelpMenu';
import useLayoutContext from 'hooks/useLayoutContext';
import useViewport from 'hooks/useViewports';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#56aba9',
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: 'auto',
        height: '100vh',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

const Layout = (props) => {
    const { ready: layoutReady, mdUp } = useViewport();
    const { setCheckingProducer } = useLayoutContext();
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const location = useLocation();
    const rawPathname = location?.pathname?.substring(1);

    const keycloakCtx = useContext(KeycloakContext);
    const isStore = keycloakCtx.keycloak.hasResourceRole('store');
    const isSuperuser = keycloakCtx.keycloak.hasResourceRole('superuser');

    // eslint-disable-next-line no-unused-vars
    const { data: producerInitData, loading: loadingProducerInit, error: errorProducerInit } = useQuery(QUERY_INIT_PRODUCER_CHECK, {
      fetchPolicy: 'cache-and-network',
    });

    let title = () => {
        const v = rawPathname;
        const isNonFood = extractURLParam('nonfood', window.location) === "1";
        if (!v || v === '') {
            return 'Dashboard';
        } else if (v.startsWith('producers/data') || v.startsWith('superuser/data')) {
            return 'Data';
        } else if (v.startsWith('me/products')) {
            return 'My Products';
        } else if ((isStore || isSuperuser) && v.startsWith('products')) {
            return 'All Products';
        } else if (v.startsWith('superuser/products')) {
            return 'Product Approval';
        } else if (v.startsWith('superuser/producers')) {
            return 'Producers Approval';
        } else if (v.startsWith('superuser/organizations')) {
            return 'Organizations';
        } else if (v.startsWith('superuser/export-history')) {
          return 'Export History';
        } else if (v.startsWith('superuser/bulk-import')) {
            return 'Bulk Import';
        } else if (v.startsWith('superuser/export')) {
            return isNonFood ? 'Export Non Food' : 'Export Products';
        } else if (v.startsWith('superuser/failed')) {
            return 'Failed Products';
        } else if (isSuperuser && (v === 'producers' || v.startsWith('producers?'))) {
            return 'All Producers';
        } else if (v.startsWith('superuser/producer/') && v.endsWith('/edit')) {
            return 'Edit Producer';
        } else if (v.startsWith('superuser/organization/') && v.endsWith('/edit')) {
            return 'Edit Organization';
        } else if (v.startsWith('me/') && v.endsWith('/edit')) {
            return 'Edit';
        } else if (v.startsWith('products/') && v.endsWith('/edit')) {
            return 'Edit Product';
        } else if (v.startsWith('producers/') && v.endsWith('/show')) {
            return 'Producer';
        } else if (v.startsWith('products/') && v.endsWith('/show')) {
            return 'Products';
        } else if (v.startsWith('producers/') && v.endsWith('/products')) {
            return 'Products';
        } else if (v.startsWith('bulk-import')) {
            return 'Bulk Import';
        } else if (v.startsWith('notification/')) {
            return 'Notification';
        } else if (v.endsWith('/addproduct')) {
            return 'Add Product';
        } else if (v.startsWith('order/')) {
            return 'Orders';
        } else {
            return v[0].toUpperCase() + v.slice(1);
        }

        // const values = location?.pathname?.substring(1)?.split('/');
        // const value = values && values[0];
        // if(!value || value === '') {
        //     return 'Dashboard';
        // } else if (value === 'myproducts') {
        //     return 'My Products';
        // } else {
        //     return value[0].toUpperCase() + value.slice(1);
        // }
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    useEffect(() => {
      if (layoutReady) {
        setOpen(mdUp);
      }
    }, [layoutReady, mdUp]);

    useEffect(() => {
      setCheckingProducer(loadingProducerInit);
    }, [loadingProducerInit, setCheckingProducer]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Open drawer"
                        onClick={handleDrawerOpen}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        {title()}
                    </Typography>
                    <HelpMenu />
                    <AuthMenu />
                </Toolbar>
            </AppBar>
            <Drawer
                variant={mdUp ? "permanent" : undefined}
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose
                    ),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List onClick={() => !mdUp && setOpen(false)}>
                    {React.createElement(props.menu)}
                </List>
            </Drawer>

            <main id="page-main" className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container
                  className={classes.container}
                  maxWidth={rawPathname.endsWith('bulk-import') ? "xl" : "lg"}
                >
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </Container>
                <Footer />
            </main>
        </div>
    );
}
export default Layout;
