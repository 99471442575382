import { useCallback, useContext } from 'react';
import axios from 'axios';
import { path } from 'ramda';

import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';
import { restEndpoints } from 'constants/domain';

import { KeycloakContext } from 'components/Secured';
import { getFullCategoryTree } from 'components/product/ProductUpdateForm/fields/category';

const useAiProduct = () => {
  const keycloakCtx = useContext(KeycloakContext);
  const token = path(['keycloak', 'token'], keycloakCtx);

  const aiGenerateSortText = useCallback(
    async ({
      imageUrl,
      coopExportProduct,
      product,
      rootCategory,
    }: {
      imageUrl: string;
      coopExportProduct?: IProduct;
      product?: IProduct;
      rootCategory?: IFoodlaCategoryOption;
    }): Promise<{ data?: string; error?: string }> => {
      let fullCategoryTree = {
        id: product?.foodlaCategory?.id,
        name: product?.foodlaCategory?.name,
      };
      if (product?.foodlaCategory && rootCategory) {
        fullCategoryTree = getFullCategoryTree(product.foodlaCategory, rootCategory);
      }

      const formData = new FormData();
      formData.append('field', 'short_text' as keyof IProduct);
      formData.append('imageUrl', imageUrl);
      formData.append('coopExportProduct', JSON.stringify(coopExportProduct));
      formData.append('fullCategoryTree', JSON.stringify(fullCategoryTree));

      try {
        const { data } = await axios.post<{ data: string }>(restEndpoints.aiProduct, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        return data;
      } catch (error: any) {
        return { error: error.message };
      }
    },
    [token]
  );

  return { aiGenerateSortText };
};

export default useAiProduct;
