import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import axios from 'axios';
import { uniqBy } from 'ramda';
import _ from 'lodash';

import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/button';

import { KeycloakContext } from 'components/Secured';
import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

import IProduct from 'models/product';
import { IImageFileSource } from '../types';
import { S3_SIGNED_REQUEST_MUTATION } from 'graphql/mutations';
import { getImageWithBGRemoved } from 'utils/images';
import { getImageDataListToUpload, parseImageFile, sortImagesByName } from '../utils';
import { encodeLastPathUrl } from 'utils/imgix';

const API_TIMEOUT = 60 * 1000;

type TSignedRequest = {
  signedRequest: string;
  url: string;
  pictureUrl: string;
  picturePath: string;
  fileName: string;
};

type TSignedRequestResponse = {
  getS3SignRequest: {
    requests: TSignedRequest[];
  };
};

const delay = (time: number) => new Promise(resolve => setTimeout(() => resolve(''), time));

function UploadImageButton() {
  const { keycloak } = useContext(KeycloakContext);
  const [s3SignedRequest] = useMutation<TSignedRequestResponse>(S3_SIGNED_REQUEST_MUTATION, { refetchQueries: [] });

  const { step, producerData, imageSettings, uploadProgress, setUploadProgress, setErrorList } = useControlContext();
  const { dataList, productImageDataSet, onAllImageFileDataChange } = useDataContext();

  const keepRef = useRef({ shouldDelayGenerateAI: false });

  const [uploading, setUploading] = useState(false);

  const uploadableImageDataList = useMemo(
    () => getImageDataListToUpload(productImageDataSet, dataList),
    [productImageDataSet, dataList]
  );

  const getSignedRequest = async (product: IProduct, imageData: IImageFileSource) => {
    if (!imageData.file) return null;

    try {
      const { data, errors } = await s3SignedRequest({
        variables: {
          input: {
            ean: _.toString(product.EAN),
            producerName: producerData.username,
            files: [{ fileName: imageData.fileName, fileType: (imageData.convertedFile || imageData.file).type }],
            renameFiles: true,
          },
        },
      });
      if (!errors) {
        return data?.getS3SignRequest?.requests?.[0];
      }
    } catch {
      return null;
    }
    return null;
  };

  const generateAiImageData = async (imageData: IImageFileSource): Promise<IImageFileSource> => {
    const token = keycloak?.token || '';

    if (keepRef.current.shouldDelayGenerateAI) {
      // delay 2 seconds
      await delay(2000);
    }

    const removedBGImage: any = await getImageWithBGRemoved(
      imageData.convertedFile || imageData.file,
      token,
      API_TIMEOUT
    );
    keepRef.current.shouldDelayGenerateAI = true;

    let parsedImageData: IImageFileSource = { ...imageData, source: '', url: '' };
    if (!removedBGImage.error) {
      parsedImageData = await parseImageFile({ file: removedBGImage as File, token });
    }
    const name = `${imageData.name}AIGENERATED`;
    const id = `${imageData.id}AIGENERATED`;
    const aiImageData: IImageFileSource = {
      ...parsedImageData,
      id,
      assignProductId: imageData.assignProductId,
      name,
      generateAiError: removedBGImage.error,
      uploadError: removedBGImage.error,
      fileName: `${name}.png`,
    };

    return aiImageData;
  };

  const uploadImage = async (product: IProduct, imageData: IImageFileSource): Promise<IImageFileSource> => {
    const { convertedFile, file } = imageData;
    const uploadedImageData: IImageFileSource = { ...imageData, source: '', url: '', uploadError: '' };

    const signedRequest = await getSignedRequest(product, imageData);

    if (!signedRequest) {
      uploadedImageData.uploadError = `Error Get Signed Request`;
      setUploadProgress(state => ({ ...state, failedTotal: (state.failedTotal || 0) + 1 }));
      return uploadedImageData;
    }

    try {
      await axios.put(signedRequest.signedRequest, convertedFile || file, {
        timeout: API_TIMEOUT,
        headers: { 'Content-Type': (convertedFile || file)!.type },
      });
      uploadedImageData.url = encodeLastPathUrl(signedRequest.pictureUrl);
    } catch (error: any) {
      setUploadProgress(state => ({ ...state, failedTotal: (state.failedTotal || 0) + 1 }));
      uploadedImageData.uploadError = `Error Upload Image: ${error.message}`;
    }

    return uploadedImageData;
  };

  const uploadProductImage = async (
    product: IProduct,
    imageData: IImageFileSource,
    isFirst: boolean
  ): Promise<void> => {
    const uploadedImageDataList: IImageFileSource[] = [];

    // ** Original image **
    let uploadedOriginalImageData = { ...imageData };
    // check if image was uploaded
    if (!imageData.url) {
      // upload original images
      uploadedOriginalImageData = await uploadImage(product, imageData);
      setUploadProgress(state => ({ ...state, uploadedTotal: state.uploadedTotal + 1 }));
    }
    uploadedImageDataList.push(uploadedOriginalImageData);

    // ** AI image **
    let uploadedAiImageData: IImageFileSource | undefined = undefined;
    if (isFirst && !imageSettings.aiIgnore) {
      let aiImageData: IImageFileSource | undefined;

      // ** generate AI image **
      if (imageData.generateAiError || !imageData.aiImageData) {
        // generate ai image
        if (imageData.convertedFile || imageData.file) {
          const generatedAiImage = await generateAiImageData(imageData);
          aiImageData = generatedAiImage;

          if (generatedAiImage.generateAiError) {
            uploadedOriginalImageData.generateAiError = generatedAiImage.generateAiError;

            setUploadProgress(state => ({ ...state, failedTotal: (state.failedTotal || 0) + 1 }));
          } else {
            uploadedOriginalImageData.generateAiError = undefined;
            // keep ai image for reuploading if need
            uploadedOriginalImageData.aiImageData = generatedAiImage;
          }
        }
      } else {
        aiImageData = { ...imageData.aiImageData };
      }

      // ** upload AI image **
      if (aiImageData && (!uploadedOriginalImageData.generateAiError || uploadedOriginalImageData.uploadAiError)) {
        // upload ai image
        uploadedAiImageData = await uploadImage(product, aiImageData);

        if (uploadedAiImageData.uploadError) {
          uploadedOriginalImageData.uploadAiError = uploadedAiImageData.uploadError;
          aiImageData.uploadError = uploadedAiImageData.uploadError;
        } else {
          uploadedOriginalImageData.uploadAiError = undefined;
          aiImageData = uploadedAiImageData;
          aiImageData.file = undefined;
          aiImageData.convertedFile = undefined;
          // remove aiImage from imageData if ai image was uploaded successfully
          uploadedOriginalImageData.aiImageData = undefined;
        }
      }

      if (aiImageData) {
        uploadedImageDataList.push(aiImageData);
      }
      setUploadProgress(state => ({ ...state, uploadedTotal: state.uploadedTotal + 1 }));
    }

    // remove file from original image data when has no error
    if (
      !uploadedOriginalImageData.uploadAiError &&
      !uploadedOriginalImageData.generateAiError &&
      !uploadedOriginalImageData.uploadAiError
    ) {
      uploadedOriginalImageData.aiImageData = undefined;
      uploadedOriginalImageData.file = undefined;
      uploadedOriginalImageData.convertedFile = undefined;
    }

    await onAllImageFileDataChange(oldList => uniqBy(item => item.id, [...uploadedImageDataList, ...oldList]));
  };

  const uploadProductImageList = async (product: IProduct, originalImageDataList: IImageFileSource[]) => {
    let imageDataList = sortImagesByName(originalImageDataList);
    // ** upload images **
    for (let index = 0; index < imageDataList.length; index++) {
      const originalImageData = imageDataList[index];
      await uploadProductImage(product, originalImageData, index === 0);
    }
    setUploadProgress(state => ({ ...state, uploaded: [...state.uploaded, product.id || ''] }));
  };

  const handleUploadData = async () => {
    keepRef.current.shouldDelayGenerateAI = false;

    const numberOfAiImages = uniqBy(({ assignProductId }) => assignProductId, uploadableImageDataList).length;

    setUploading(true);
    setUploadProgress({
      isUploading: true,
      total: imageSettings.aiIgnore
        ? uploadableImageDataList.length
        : uploadableImageDataList.length + numberOfAiImages,
      uploadedTotal: 0,
      failedTotal: 0,
      uploading: uploadableImageDataList.map(({ assignProductId }) => assignProductId || ''),
      uploaded: [],
    });

    // ** upload products **
    for (let index = 0; index < dataList.length; index++) {
      const uploadingProduct = dataList[index];
      const productOriginalImageDataList = uploadableImageDataList.filter(
        ({ assignProductId }) => assignProductId === uploadingProduct.id
      );
      await uploadProductImageList(uploadingProduct, productOriginalImageDataList);
    }

    setUploading(false);
    setUploadProgress(state => ({
      ...state,
      // to keep "Loading images" progress bar in the screen, don't reset [total] and [uploadedTotal]
      uploadedTotal: state.total,
      isUploading: false,
      uploaded: [],
      uploading: [],
    }));
  };

  useEffect(() => {
    if (uploadProgress.failedTotal) {
      setErrorList(state => [
        ...state.filter(error => error.step !== step),
        { step, message: 'Unable to create AI-images for some images' },
      ]);
    }
  }, [step, uploadProgress, setErrorList]);

  const disabledImport = !producerData.username || uploadProgress.isUploading || !uploadableImageDataList.length;

  return (
    <Button variant="contained" disabled={uploading || disabledImport} onClick={handleUploadData}>
      {uploading ? <CircularProgress size={16} style={{ margin: 4 }} /> : 'Ladda upp bilder'}
    </Button>
  );
}

export default UploadImageButton;
