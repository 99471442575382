import { FC, useMemo, useState } from 'react';

import { Box, Dialog, DialogContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/button';
import VirtualScrollbar from 'components/VirtualScrollbar';
import Pagination from '../components/Pagination';
import ReviewTable from './ReivewTable';

import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

import { mapExcelColumn, removeUnnecessaryColumns, updateMandatoryColumns, writeExcelFile } from '../utils/excel';

interface DownloadReviewModalProps {
  open: boolean;
  onClose: () => void;
}

const DownloadReviewModal: FC<DownloadReviewModalProps> = ({ open, onClose }) => {
  const { productType, limit, tableDownloadContainerElement } = useControlContext();
  const { excelFile, dataList, excelColumnList } = useDataContext();

  const [isDownloading, setDownloading] = useState(false);

  const downloadColumnList = useMemo(() => {
    let columnList = excelColumnList
      .filter(({ exportable }) => exportable)
      .map(column => mapExcelColumn(column, dataList));

    columnList = updateMandatoryColumns({ columnList, dataList });
    columnList = removeUnnecessaryColumns({ productType, columnList });
    return columnList;
  }, [excelColumnList, dataList, productType]);

  const handleDownload = async () => {
    if (!excelFile) return;
    setDownloading(true);
    const splitedName = excelFile.name.split('.');
    splitedName.pop();
    const fileName = splitedName.join('.');
    await writeExcelFile(downloadColumnList, dataList, excelFile, {
      fileName: `${fileName} - Foodla import check`,
    });
    setDownloading(false);
    onClose();
  };

  return (
    <Dialog open={open} fullScreen onClose={onClose}>
      <Box display="flex" alignItems="center" justifyContent="space-between" px={3} py={2}>
        <Box sx={{ fontSize: 20, lineHeight: 1, color: '#383D52' }}>Excel download preview</Box>
        <IconButton size="small" disabled={isDownloading} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent style={{ color: '#383D52' }}>
        {isDownloading ? (
          <Box display="flex" justifyContent="center" py={10}>
            Downloading...
          </Box>
        ) : (
          <>
            <VirtualScrollbar targetElement={tableDownloadContainerElement} bottom={-8}>
              <ReviewTable isReview dataList={dataList} columnList={downloadColumnList} defaultLimit={limit} />
            </VirtualScrollbar>

            <Box display="flex" justifyContent="center" mt={2}>
              <Pagination />
            </Box>
          </>
        )}
      </DialogContent>
      <Box display="flex" alignItems="center" justifyContent="end" px={3} py={2}>
        <Button variant="contained" disabled={isDownloading} onClick={handleDownload}>
          Download
        </Button>
      </Box>
    </Dialog>
  );
};

export default DownloadReviewModal;
