import React from 'react';

import IProduct from '../../../../models/product';
import InputTextField from '../../../inputfields/InputTextField';

interface IBrandProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  isFood?: boolean;
}

const Brand = ({ state, setState, isFood }: IBrandProps) => {
  const name = isFood ? 'brand_food' : 'brand';
  // TODO any
  const handleChange = ({ target }: any) => setState({ ...state, [name]: target.value });
  // console.log('!!!state', state);
  return (
    <InputTextField<IProduct>
      label="Varumärke"
      dense={false}
      inputName={name}
      value={state[name]}
      onChange={handleChange}
      disabled={state?.isMagazine}
      withLabel
      labelOptions={{
        heading: 'Varumärke',
        text: `Artikelns varumärke. Om artikeln består av flera olika artiklar med olika varumärken, anges valfritt ingående varumärke. Om ej särskilt varumärke finns kan denna kolumn lämnas tom.
        (Samma varumärke som eventuellt angetts i fältet ”Varumärke/Utökad artikelbenämning”)`,
        required: true
      }}
    />
  );
}

export default Brand;
