// Dependencies
import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Card, Divider } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';

// Components
import PagePagination from '../Pagination';
import LoadingSkeleton from '../loadingIndicator/ListLoadingSkeleton';
import CardGrid from '../CardGrid';
import ProducerCard from './ProducerCard';
import ProducerMapList from './ProducerMapList';
import { ResponseSnackBar } from '../snackbar/SnackBar';
import TextField from '@material-ui/core/TextField';

// Utils
import { isDefined, isNotDefined, toSafeNumber, isNumber, toSafeFloat, parseBoolean } from '../../utils/helpers';
import { addOrChangeQueryParam, pushToWindowHistory, extractURLParam } from '../../utils/urlUtils';

// Constants
import { PAGINATION, isValidSortType, isValidSortOrder, IS_FOOD_LOCAL_STORAGE_KEY } from '../../constants/domain';
import useSearchParams from 'hooks/useSearchParams';

const INIT_PAGE_PARAM = 1;
const INIT_LIMIT_PARAM = 20;
const INIT_SORT_BY = PAGINATION.SORTING_TYPES.APPROVED;
const INIT_SORT_ORDER = PAGINATION.SORT_ORDERS.ASC;
// Using Stockholm coordinates initially if not set.
// 59.336378, 18.063125
const INIT_LATITUDE = 59.336378;
const INIT_LONGITUDE = 18.063125;

const ProducerCardListView = ({
  list,
}) => {
  return (
    <CardGrid list={list}>
      {props => (
          <ProducerCard {...props} />
      )}
    </CardGrid>
  );
};

const ProducerCardList = ({ producersQuery, variables, showSearch }) => {
  const { searchParams, updateSearchParams } = useSearchParams();
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [query, setQuery] = React.useState(searchParams.search || "");

  const [summary, setSummary] = useState(null);
  const [mode, setMode] = useState('list');

  // Handle sorting params
  let sortByInit = extractURLParam('sortby', window.location);
  let sortOrderInit = extractURLParam('order', window.location);
  let latitudeInit = extractURLParam('latitude', window.location);
  let longitudeInit = extractURLParam('longitude', window.location);

  const lsSortBy = localStorage.getItem('producers-sortBy');
  if (isDefined(sortByInit) && isValidSortType(sortByInit)) {
  } else if (isDefined(lsSortBy) && isValidSortType(lsSortBy)) {
    sortByInit = lsSortBy;
  } else {
    sortByInit = INIT_SORT_BY;
  }
  // sortByInit = isDefined(sortByInit) && isValidSortType(sortByInit) ? sortByInit : INIT_SORT_BY;

  const lsSortOrder = localStorage.getItem('producers-sortOrder');
  if (isDefined(sortOrderInit) && isValidSortOrder(sortOrderInit)) {
  } else if (isDefined(lsSortOrder) && isValidSortType(lsSortOrder)) {
    sortOrderInit = lsSortOrder;
  } else {
    sortOrderInit = INIT_SORT_ORDER;
  }
  // sortOrderInit = isDefined(sortOrderInit) && isValidSortOrder(sortOrderInit) ? sortOrderInit : INIT_SORT_ORDER;

  const lsLatitude = localStorage.getItem('producers-latitude');
  if (isDefined(latitudeInit)) {
    latitudeInit = toSafeFloat(latitudeInit, undefined);
  }
  if (!isDefined(latitudeInit) && isDefined(lsLatitude)) {
    latitudeInit = toSafeFloat(lsLatitude, undefined);
  }
  if (!isDefined(latitudeInit)) {
    latitudeInit = INIT_LATITUDE;
  }
  // latitudeInit = isDefined(latitudeInit) ? toSafeFloat(latitudeInit, INIT_LATITUDE) : INIT_LATITUDE;

  const lsLongitude = localStorage.getItem('producers-longitude');
  if (isDefined(longitudeInit)) {
    longitudeInit = toSafeFloat(longitudeInit, undefined);
  }
  if (!isDefined(longitudeInit) && isDefined(lsLongitude)) {
    longitudeInit = toSafeFloat(lsLongitude, undefined);
  }
  if (!isDefined(longitudeInit)) {
    longitudeInit = INIT_LONGITUDE;
  }
  // longitudeInit = isDefined(longitudeInit) ? toSafeFloat(longitudeInit, INIT_LONGITUDE) : INIT_LONGITUDE;

  let limitInit = extractURLParam('limit', window.location);
  const lsLimit = localStorage.getItem('producers-limit');
  if (isDefined(limitInit)) {
    limitInit = toSafeNumber(limitInit, undefined);
  }
  if (!isDefined(limitInit) && isDefined(lsLimit)) {
    limitInit = toSafeNumber(lsLimit, undefined);
  }
  if (!isDefined(limitInit)) {
    limitInit = INIT_LIMIT_PARAM;
  }

  const [sorting, setSorting] = useState(sortByInit);
  const [sortOrder, setSortOrder] = useState(sortOrderInit);
  const [coordinates, setCoordinates] = useState({ latitude: latitudeInit, longitude: longitudeInit});
  const [locationAccessDenied, setLocationAccessDenied] = useState(false);

  let pageParam = extractURLParam('page', window.location);
  pageParam = toSafeNumber(pageParam, INIT_PAGE_PARAM);

  const [limit, setLimit] = useState(limitInit);
  const numberOfPages = summary ? Math.ceil(summary.total / limit) : 1;

  const startPageCount = pageParam > numberOfPages ? numberOfPages : pageParam;

  const [nrPages, setNrPages] = useState(numberOfPages);
  const [currentPage, setCurrentPage] = useState(startPageCount);

  // const isFood = localStorage.getItem(IS_FOOD_LOCAL_STORAGE_KEY);
  // TODO
  const isFood = null;

  let qVariables = {
    pagePagination: {
      limit,
      page: currentPage,
      sorting,
      sortOrder,
      longitude: coordinates.longitude,
      latitude: coordinates.latitude,
    },
    textQuery: showSearch && query,
  };

  if(isFood != null) {
    qVariables.isFood = parseBoolean(isFood);
  }

  if (variables) {
    qVariables = { ...qVariables, ...variables };
  }

  const { data, loading, error, refetch } = useQuery(producersQuery, {
    variables: qVariables,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!data) return;
    if (data.producersBy.producersSummary) {
      setSummary(data.producersBy.producersSummary);
    } else {
      setSummary(data.producersBy.summary);
    }
  }, [data]);

  // When user paginates.
  const onPageChange = (event, page) => {
    setCurrentPage(page);

    const newURL = addOrChangeQueryParam('page', page, window.location);
    pushToWindowHistory(newURL, window, 'ProducerPaginationPage');

    let vars = {
      pagePagination: {
        limit,
        page,
        sorting,
        sortOrder,
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
      },
    }

    if(isFood != null) {
      vars.isFood = parseBoolean(isFood);
    }
    if (variables) {
      vars = { ...vars, variables };
    }
    if (showSearch) {
      vars.textQuery = query;
    }

    refetch(vars);
  };

  // When user changes the limit of producers/page.
  const onLimitChanged = changeToLimit => {
    setLimit(changeToLimit);
    localStorage.setItem('producers-limit', changeToLimit);

    const newURL = addOrChangeQueryParam('limit', changeToLimit, window.location);
    pushToWindowHistory(newURL, window, 'ProducerPaginationLimit');

    const newPageCount = changeToLimit;

    if (currentPage > newPageCount) {
      setCurrentPage(newPageCount);
    }

    setNrPages(newPageCount);

    let vars = {
      pagePagination: {
        limit: changeToLimit,
        page: currentPage > newPageCount ? newPageCount : currentPage,
        sorting,
        sortOrder,
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
      },
    }

    if(isFood != null) {
      vars.isFood = parseBoolean(isFood);
    }
    if (variables) {
      vars = { ...vars, variables };
    }
    if (showSearch) {
      vars.textQuery = query;
    }

    refetch(vars);
  };

  const getBrowserLocation = async () => {
    const positionSuccess = (position) => ({ lat: position.coords.latitude, lng: position.coords.longitude });

    setLocationAccessDenied(false);

    const location = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => resolve(positionSuccess(position)), () => reject('Error'));
    });

    return location;
  };

  // When user changes values to sort by.
  const onSortChanged = async (type, value) => {
    let newSorting = sorting;
    let newOrder = sortOrder;
    if (type === 'sorting') newSorting = value;
    else newOrder = value;

    let latitude;
    let longitude;

    if(type === 'sorting' && value === 'distance') {
      try {
        const { lat, lng } = await getBrowserLocation();
        latitude = lat;
        longitude = lng;
        setCoordinates({ latitude, longitude });
        localStorage.setItem('producers-latitude', latitude);
        localStorage.setItem('producers-longitude', longitude);
      } catch(e) {
        setLocationAccessDenied(true);
        return;
      }
    }

    setSorting(newSorting);
    localStorage.setItem('producers-sortBy', newSorting);
    setSortOrder(newOrder);
    localStorage.setItem('producers-sortOrder', newOrder);

    let newURL = addOrChangeQueryParam('sortby', newSorting, window.location);
    newURL = addOrChangeQueryParam('order', newOrder, { href: newURL });

    if(isDefined(latitude) && isDefined(longitude)) {
      newURL = addOrChangeQueryParam('latitude', latitude, { href: newURL });
      newURL = addOrChangeQueryParam('longitude', longitude, { href: newURL });
    }

    pushToWindowHistory(newURL, window, 'ProducerSorting');

    let vars = {
      pagePagination: {
        limit,
        page: currentPage,
        sorting: newSorting,
        sortOrder: newOrder,
        latitude,
        longitude,
      },
    }

    if(isFood != null) {
      vars.isFood = parseBoolean(isFood);
    }
    if (variables) {
      vars = { ...vars, variables };
    }
    if (showSearch) {
      vars.textQuery = query;
    }

    refetch(vars);

  };

  const onModeSwitch = () => setMode(mode === 'list' ? 'map' : 'list');

  let listData;
  if (isDefined(data) && !error) {
    listData = data.producersBy.data;
  }

  // If the limit has changed, we might have to adjust which page we are viewing since the nr of pages with more producers
  // on each page could exceed the total amount of pages required.
  if ((isNumber(nrPages) && isNumber(numberOfPages)) && (currentPage > numberOfPages)) setCurrentPage(numberOfPages);
  // If the limit has changed. The number of pages calculated may differ from whats been set in the state. This is checked and updated here.
  if ((isNumber(nrPages) && isNumber(numberOfPages)) && (nrPages !== numberOfPages)) setNrPages(numberOfPages);

  React.useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);
    } else {
      if (refetch && showSearch) {
        let vars = {
          pagePagination: {
            limit,
            page: currentPage,
            sorting,
            sortOrder,
            longitude: coordinates.longitude,
            latitude: coordinates.latitude,
          },
        };
  
        if(isFood != null) {
          vars.isFood = parseBoolean(isFood);
        }
        if (variables) {
          vars = { ...vars, variables };
        }
        if (showSearch) {
          vars.textQuery = query;
        }
    
        refetch(vars);
      }
    }
  }, [query]);
  return (
    <>
      {locationAccessDenied && <ResponseSnackBar title='Location' message="We could not access your location info. Can't sort by distance." success={false}/>}
      {isDefined(summary) && (
        <Card>
          <PagePagination
            page={currentPage}
            onPageChange={onPageChange}
            count={summary?.total}
            limit={limit}
            onLimitChanged={onLimitChanged}
            showSortingOptions
            onSortChanged={onSortChanged}
            sorting={sorting}
            sortOrder={sortOrder}
            type='Producer'
          />
        </Card>
      )}
      <Divider />
      {showSearch && (
        <TextField
          placeholder="Search by Producer Name"
          variant="outlined"
          value={query}
          // disabled={loading}
          style={{marginLeft: 10, marginRight: 10, marginTop: 10, marginBottom: 10, width: 500}}
          onChange={(e) => {
              setQuery(e.target.value);
              updateSearchParams({ search: e.target.value });
          }}
        />
      )}
      {loading && <LoadingSkeleton count={limit} />}
      {error && <p>Error :(</p>}
      {!loading && isNotDefined(data) && <p>No data...</p>}
      {!error && !loading && isDefined(listData) && (
        <Box>
          <Box style={{ marginLeft: '12px' }}>
            <FormControlLabel
                control={
                  <Switch
                      checked={mode === 'map'}
                      onChange={onModeSwitch}
                  />
                }
                label="Map View"
            />
          </Box>
          {
            mode === 'list'
              ? <ProducerCardListView refetch={refetch} list={listData || []} />
              : <ProducerMapList list={listData || []} />
          }
        </Box>
      )}
      {isDefined(summary) && (
        <Card>
          <PagePagination
            page={currentPage}
            onPageChange={onPageChange}
            count={summary?.total}
            limit={limit}
            onLimitChanged={onLimitChanged}
            type='Producer'
          />
        </Card>
      )}
    </>
  );
};

export default ProducerCardList;
