import { FC } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/button';
import { Box } from '@material-ui/core';

export interface ConfirmModalProps {
  open: boolean;
  title?: string;
  content: string;
  onCancel?: () => void;
  onOk?: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({ open, title, content, onCancel, onOk }) => {
  const handleOk = () => {
    onOk?.();
    onCancel?.();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Box display="flex" gridGap={16} px={2} py={1}>
          <Button size="small" onClick={onCancel} variant="outlined">
            No
          </Button>
          <Button size="small" onClick={handleOk} variant="contained" autoFocus>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmModal;
