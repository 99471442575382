import { FC, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  makeStyles,
} from '@material-ui/core';

import IProduct from 'models/product';
import { IFoodlaCategoryOption } from 'models/category';

import useViewport from 'hooks/useViewports';
import useAiProduct from 'hooks/useAiProduct';
import InputTextField from 'components/inputfields/InputTextField';
import ErrorIcon from 'components/icons/ErrorIcon';
import { IImage } from 'components/images/ImageSorter';

import ChatGptLogo from '../../../../assets/chatgpt-logo.png';

const useStyles = makeStyles(() => ({
  paperMd: { minWidth: 600, minHeight: 300 },
}));

interface ShortTextProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  approve?: boolean;
  sortedImages: IImage[];
  coopExportProduct?: IProduct;
  rootCategory?: IFoodlaCategoryOption;
}

const ShortText: FC<ShortTextProps> = ({ state, setState, approve, sortedImages, coopExportProduct, rootCategory }) => {
  const classes = useStyles();
  const { mdUp } = useViewport();
  const { aiGenerateSortText } = useAiProduct();

  const [loading, setLoading] = useState(false);
  const [openAiDialog, setOpenAiDialog] = useState(false);
  const [aiGeneratedValue, setAiGeneratedValue] = useState('');
  const [aiError, setAiError] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: value });
  };

  const handleAiGenerate = async () => {
    setLoading(true);
    setAiError(false);
    setOpenAiDialog(true);

    const { data } = await aiGenerateSortText({
      imageUrl: sortedImages[0].pictureUrl || '',
      coopExportProduct,
      product: state,
      rootCategory,
    });

    if (data) {
      setAiGeneratedValue(data);
    } else {
      setAiError(true);
    }

    setLoading(false);
  };

  const handleApply = () => {
    setState({ ...state, short_text: aiGeneratedValue || '' });
    setOpenAiDialog(false);
  };

  return (
    <>
      <InputTextField
        style={{ marginTop: 7 }}
        label="Beskriv produkten med max 150 ord"
        multiline
        disabled={state?.isMagazine}
        inputName="short_text"
        onChange={handleChange}
        setState={setState}
        approve={approve}
        state={state}
        multilineRows={2}
        multilineMax={20}
        value={state.short_text}
        withLabel
        labelOptions={{
          heading: 'Produktbeskrivning',
          required: true,
          text: 'Tänk på att säljande utryck som ger intrycket av att en viss produkt har bättre kvalitet än andra behöver förklaras för att inte vilseleda konsumenten.',
          link: 'https://kontrollwiki.livsmedelsverket.se/artikel/36/saljande-uttryck',
        }}
        aiButton={
          process.env.REACT_APP_AUTH_ENV !== 'prod' && (
            <Tooltip placement="top-end" title="AI generera Produktbeskrivning" arrow>
              <IconButton size="small" onClick={handleAiGenerate}>
                {<img src={ChatGptLogo} alt="chat-gpt-logo" style={{ width: 24 }} />}
              </IconButton>
            </Tooltip>
          )
        }
      />

      <Dialog open={openAiDialog} fullScreen={!mdUp} classes={{ paper: mdUp ? classes.paperMd : '' }}>
        <DialogTitle>AI generera Produktbeskrivning</DialogTitle>

        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            flex="1"
            display="flex"
            alignItems={loading || aiError ? 'center' : undefined}
            justifyContent={loading || aiError ? 'center' : undefined}
            width="100%"
            height="100%"
          >
            {loading ? (
              <CircularProgress size={48} style={{ display: 'block' }} />
            ) : aiError ? (
              <p style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                <ErrorIcon width="24" height="24" />
                <span style={{ color: 'red', fontSize: 18 }}>Generate data failed</span>
              </p>
            ) : (
              <p style={{ whiteSpace: 'pre-line', margin: 0 }}>{aiGeneratedValue}</p>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          <Box display="flex" gridGap={16} px={2} py={1}>
            <Button size="small" color="secondary" variant="contained" onClick={() => setOpenAiDialog(false)}>
              Cancel
            </Button>
            <Button disabled={!aiGeneratedValue} size="small" color="primary" variant="contained" onClick={handleApply}>
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShortText;
