import React, { useContext } from 'react';
import { gql } from 'apollo-boost';
import withContainer from '../../hoc/withContainer';
import { parseBoolean } from '../../utils/helpers';
import SimpleText from './SimpleText';
import isNil from 'lodash/isNil';
import { KeycloakContext } from '../../components/Secured';

const query = gql`
  query ($isFood: Boolean, $filterStoresByOrg: Boolean, $onlyApproved: Boolean, $showAll: Boolean){
    productsSummary(isFood: $isFood, filterStoresByOrg: $filterStoresByOrg, onlyApproved: $onlyApproved, showAll: $showAll) {
      total
    }
  }
`;

interface IProductsSummary {
  total?: number;
}

interface IData {
  productsSummary?: IProductsSummary;
}

interface IVariables {
  isFood?: boolean;
  filterStoresByOrg?: boolean;
  showAll?: boolean;
}

const validator = (data: IData) => {
  return data?.productsSummary?.total;
}

const resolver = (data: IData) => {
  return data?.productsSummary?.total
}

const ProductTotal = (isFood?: boolean | string, showAll?: boolean) => {
  const keycloakCtx = useContext(KeycloakContext);
  const isAdmin: boolean = keycloakCtx.keycloak.hasResourceRole('admin');
  const variables: IVariables = {};
  if (showAll) {
    variables.showAll = true;
  } else {
    if (!isNil(isFood)) {
      if (typeof isFood === 'boolean') {
        variables.isFood = isFood;
      } else if (typeof isFood === 'string') {
        variables.isFood = parseBoolean(isFood);
      }
    }
    if (isAdmin) {
      if (isNil(isFood) || isFood === 'true' || isFood === true) {
        variables.isFood = true;
      } else {
        variables.isFood = false;
      }
      variables.filterStoresByOrg = true;        
    }
  }
  return withContainer({ 
    query,
    variables,
    validator,
    resolver
  })(SimpleText);
}

export default ProductTotal;
