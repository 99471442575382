import { FC, useMemo } from 'react';

import IProduct from 'models/product';
import DocumentReviewIcon from 'components/icons/DocumentReviewIcon';
import MappingArrowIcon from 'components/icons/MappingArrowIcon';
import ErrorIcon from 'components/icons/ErrorIcon';
import TickIcon from 'components/icons/TickIcon';
import TruncateText from 'components/TruncateText';
import SelectField, { getDisplayFieldName } from '../components/SelectField';

import useControlContext from '../hooks/useControlContext';
import useDataContext from '../hooks/useDataContext';

import { IImportColumn } from '../types';
import { isErrorCell } from '../utils/excel';

import { useStyles } from './styles';

interface ReviewValueProps {
  value: string | number;
}

const ReviewValue: FC<ReviewValueProps> = ({ value }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: 8, fontSize: 14 }}>
      <div style={{ flexBasis: 24, height: 24 }}>
        <DocumentReviewIcon />
      </div>
      <TruncateText title={String(value)} />
    </div>
  );
};

interface MappingRowProps {
  column: IImportColumn;
  showErrorOnly: boolean;
}

const MappingRow: FC<MappingRowProps> = ({ column, showErrorOnly }) => {
  const classes = useStyles();
  const { categoryColumnList } = useControlContext();
  const { dataList, onRemoveColumn, onUpdateColumnField } = useDataContext();

  const isMissingField = useMemo(() => !column.field, [column]);
  const isWrongValue = useMemo(
    () => !isMissingField && dataList.some(data => isErrorCell(column, data)),
    [isMissingField, column, dataList]
  );

  const isError = isMissingField || isWrongValue;

  if (showErrorOnly && !isError) return null;

  return (
    <div className={classes.row}>
      <div
        className={classes.mainCell}
        style={{ background: 'FAFBFF', border: '1px solid #D7DAE4', borderRadius: 4, padding: '7px 8px' }}
      >
        <ReviewValue value={column.title} />
      </div>

      <div className={classes.arrowCell} style={{ color: isError ? '#D32F2F' : '#56ABA9' }}>
        <MappingArrowIcon />
      </div>

      <div className={classes.mainCell}>
        <SelectField
          columnId={column._id}
          error={isMissingField}
          value={column.field}
          categoryColumnList={categoryColumnList}
          renderValue={value => (
            <ReviewValue value={isMissingField ? '- - -' : getDisplayFieldName(value as keyof IProduct)} />
          )}
          onRemoveColumn={onRemoveColumn}
          onUpdateColumnField={onUpdateColumnField}
        />
      </div>

      <div className={classes.mainCell} style={{ display: 'flex', gap: 8 }}>
        <div>
          {isMissingField && <ErrorIcon />}
          {isWrongValue && <ErrorIcon symbolColor="#ea580c" />}
          {!isMissingField && !isWrongValue && <TickIcon />}
        </div>
        <div>
          <div style={{ fontSize: 12 }}>
            {isMissingField && 'Inte matchat'}
            {isWrongValue && <span style={{ color: '#ea580c' }}>Granskning rekommenderas</span>}
            {!isMissingField && !isWrongValue && 'Korrekt matchning'}
          </div>
          <div style={{ fontSize: 10, color: '#737DA0' }}>
            {isMissingField && 'Vi hittade ingen lämplig kolumn för att matcha den'}
            {isWrongValue && 'Vissa fält har olämpliga värden'}
            {!isMissingField && !isWrongValue && 'Matchas automatiskt'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MappingRow;
