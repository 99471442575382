import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import IProduct from "models/product";

interface IReachSubstancesFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
};

const labelStyles = {
  margin: 0,
}

const ReachSubstancesFields = ({ state, setState}: IReachSubstancesFieldsDataProps) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%'}}>
      <FormControl component="fieldset">
        <InputLabel 
          heading="Överensstämmer produkten med REACH-förordningen (EG) 1907/2006?"
          text="Reach-förordningen innehåller regler om registrering, utvärdering, tillstånd och begränsningar av kemiska ämnen."
          link="https://www.kemi.se/lagar-och-regler/reach-forordningen"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.complianceReach19072006}
          name="complianceReach19072006"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset">
        <InputLabel 
          heading="Innehåller produkten något ämne med tillståndsplikt som anges i bilaga XIV till REACH-förordningen (EC) 1907/2006?"
          text="För att få använda eller släppa ut vissa särskilt farliga ämnen på marknaden behöver du tillstånd. Det gäller oavsett i vilken mängd du hanterar dessa ämnen. Vilka ämnen det gäller hittar du i bilaga XIV till Reach-förordningen."
          link="https://www.kemi.se/lagar-och-regler/lagstiftningar-inom-kemikalieomradet/eu-gemensam-lagstiftning/reach-forordningen/tillstand-i-reach-for-sarskilt-farliga-amnen"
          labelStyles={labelStyles}
          required
        />
        <RadioGroup
          value={state?.reachSubstancesAnnex19072006}
          name="reachSubstancesAnnex19072006"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default ReachSubstancesFields;
