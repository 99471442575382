import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { concat, path } from 'ramda';
import { useLazyQuery } from '@apollo/react-hooks';
import moment from 'moment';
import styled from 'styled-components';

import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';

import useSearchParams from 'hooks/useSearchParams';

import { SUPERUSER_PRODUCTS_DETAILED_QUERY, SUPERUSER_PRODUCTS_DETAILED_SUMMARY_QUERY } from '../../graphql/queries';
import { isDefined } from '../../utils/helpers';
import { IMAGE_SIZES, getImageWithSizeSuffix } from 'utils/images2';
import { toSafeNumber } from 'utils/helpers-ts';
import COLORS from '../../constants/colors';
import { SUPERUSER_APPROVAL } from '../../components/constants';
import { GreenBlinkingBadge, RedBlinkingBadge } from '../../components/badges/badges';
import IProduct from '../../models/product';
import ErrorBox from '../../components/Error';
import { Grid, TextField } from '@material-ui/core';
import { BasicTitle } from '../../components/typography/Title';
import PagePagination from '../../components/Pagination';
import { SUPERUSER_PRODUCTS_PAGE_LIST } from 'components/product/ProductUpdateForm/product-navigator';
import HighlightedText from 'components/highlightedText/HighlightedText';

interface IProductListItemProps {
  searchString?: string;
  product: IProduct;
  minimize?: boolean;
  showNotApproved?: boolean;
  showBadges?: boolean;
  showNoPictureEANTitle?: boolean;
  showUnderProcessInformationApproved?: boolean;
}

const StyledBasicTitle = styled(BasicTitle)`
  padding-top: 20px;
`;

export const ProductListItem = ({ searchString, product, minimize, showNotApproved, showBadges, showNoPictureEANTitle, showUnderProcessInformationApproved }: IProductListItemProps) => {
  const producerStatus = path(['producer', 'status'], product);
  const producerNotAccepted = isDefined(producerStatus) && producerStatus === SUPERUSER_APPROVAL.AWAITING_APPROVAL;
  if (!showNotApproved && producerNotAccepted) return <></>;

  if (showNoPictureEANTitle) {
    if (
      (product.title && product.title.trim() !== '')
      && (product.EAN && product.EAN.trim() !== '')
      && (product.image_src && product.image_src.trim() !== '')
    ) {
      return <></>;
    }
  } else {
    if (
      (!product.title || product.title.trim() === '')
      || (!product.EAN || product.EAN.trim() === '')
      || (!product.image_src || product.image_src.trim() === '')
    ) {
      return <></>;
    }
  }

  let showInfoBadge = false;
  if (!product.approve
    || product.approve.filter((x) => x.field !== 'images').length === 0
    || product.approve.filter((x) => x.field !== 'images').every((x) => x.state === true)
    ) {
    showInfoBadge = true;
  }
  if (showUnderProcessInformationApproved) {
    if (
      !product.imageUnderProcess || !showInfoBadge
    ) {
      return <></>
    }
  } else {
    if (
      product.imageUnderProcess && showInfoBadge
    ) {
      return <></>
    }
  }

  const primaryTitle = product?.title || 'NO TITLE';
  let secondaryTitle = product?.producer?.name || 'NO PRODUCER NAME';
  const linkTo = `/superuser/products/${product.id}/approval?navigator=true`;

  const image = product.image_src && isDefined(product.image_src) ? getImageWithSizeSuffix(product.image_src, IMAGE_SIZES.XSMALL) : '';
  if (producerNotAccepted) secondaryTitle = concat(secondaryTitle, ' (PRODUCER NOT ENABLED)');
  // TODO add additional images
  let showImageBadge = false;
  if (!product.approve
    || product.approve.length === 0
    || product.approve.findIndex((x) => x.field === 'images') < 0
    || product.approve.findIndex((x) => x.field === 'images' && x.state === true) >= 0
    || product.imageUnderProcess
    ) {
    showImageBadge = true
  }

  let timestamp_str;
  let ts;
  let timestamp_color = 'black';
  if (product?.timestamp) {
    ts = moment(product.timestamp, 'x');
    timestamp_str = ts.format('YYYY-MM-DD, HH:mm');
    const diff = Math.abs(ts.diff(moment()));
    if (diff <= 1000 * 60 * 60 * 24) {
      timestamp_color = 'green';
    } else if (diff <= 1000 * 60 * 60 * 72) {
      timestamp_color = 'rgb(252, 186, 3)';
    } else {
      timestamp_color = 'red';
    }
  }
  let timestamp;
  if (timestamp_str) {
    timestamp = `${product?.timestamp_type}: ${timestamp_str}`;
  }

  return (
    <>
      <Link variant="inherit" underline="none" color="inherit" component={RouterLink} to={linkTo}>
        <ListItem>
          <ListItemAvatar>
            {producerNotAccepted ? (
              <RedBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </RedBlinkingBadge>
            ) : (
              <GreenBlinkingBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar>
                  <img src={image} alt="P" />
                </Avatar>
              </GreenBlinkingBadge>
            )}
          </ListItemAvatar>
          <ListItemText
            style={producerNotAccepted ? { color: COLORS.mainRed } : {}}
            primary={<HighlightedText text={primaryTitle} searchString={searchString} />}
            secondary={<HighlightedText text={secondaryTitle} searchString={searchString} />}
          />

          {/* <ListItemText
            style={{ color: product?.timestamp_color, marginRight: 5 }}
            primary={ 'product?.timestamp' }
          /> */}
          {!minimize && (
            <Chip label={timestamp} style={{ marginRight: 5, backgroundColor: 'white', color: timestamp_color }} />
          )}
          {!minimize && (
            <Chip
              label={product?.imageUnderProcess ? 'PIC UNDER PROCESS' : 'PICTURE APPROVED'}
              style={{
                visibility: !showImageBadge ? 'hidden' : undefined,
                marginRight: 5,
                backgroundColor: product?.imageUnderProcess ? 'blue' : 'green',
                color: 'white',
              }}
            />
          )}
          {!minimize && (
            <Chip
              label="INFORMATION APPROVED"
              style={{
                visibility: !showInfoBadge ? 'hidden' : undefined,
                marginRight: 5,
                backgroundColor: 'green',
                color: 'white',
              }}
            />
          )}
        </ListItem>
      </Link>
      {!minimize && (<Divider />)}
    </>
  );
};

interface ISuperuserProductsSummary {
  superuserProductsSummary: {
    total: number;
  };
}

interface ISuperuserProducts {
  superuserProducts?: IProduct[];
}

const INIT_PAGE = 1;
const INIT_LIMIT = 24;
const LOCAL_STORE_LIMIT = 'products-approval-limit';

const getDefaultLimit = (limitParam: string) => {
  const storedLimit = localStorage.getItem(LOCAL_STORE_LIMIT) || '';
  return toSafeNumber(limitParam, 0) || toSafeNumber(storedLimit, 0) || INIT_LIMIT;
};

const ProductList = () => {
  const [loadProductSummary, { data: dataSummary, loading: loadingSummary, error: errorSummary }] =
    useLazyQuery<ISuperuserProductsSummary>(SUPERUSER_PRODUCTS_DETAILED_SUMMARY_QUERY, {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        // make sure selected page is not larger than maximum page
        const validPageValue = getValidPage(page, limit);
        if (validPageValue !== page) {
          handlePageChange(page);
        }
      },
    });
  const [loadProduct, { data, loading, error, networkStatus }] = useLazyQuery<ISuperuserProducts>(
    SUPERUSER_PRODUCTS_DETAILED_QUERY,
    { fetchPolicy: 'cache-and-network' }
  );
  const { searchParams, updateSearchParams } = useSearchParams();

  const [page, setPage] = useState(toSafeNumber(searchParams.page, 0) || INIT_PAGE);
  const [limit, setLimit] = useState(getDefaultLimit(searchParams.limit));
  const [showNotApproved, setShowNotApproved] = useState(false);
  const [showNoPictureEANTitle, setShowNoPictureEANTitle] = useState(false);
  const [showUnderProcessInformationApproved, setShowUnderProcessInformationApproved] = useState(false);
  const [searchValue, setSearchValue] = useState(searchParams.search || '');

  const queryOptions = useMemo(
    () => ({
      searchQuery: searchValue,
      isNotApprovedProducer: showNotApproved,
      isNoPictureEANTitle: showNoPictureEANTitle,
      isOnlyImageInProcess: showUnderProcessInformationApproved,
    }),
    [searchValue, showNotApproved, showNoPictureEANTitle, showUnderProcessInformationApproved]
  );

  const getValidPage = (newPage: number, newLimit: number) => {
    const maxPage = Math.ceil((dataSummary?.superuserProductsSummary?.total || 0) / newLimit);
    return Math.min(Math.max(maxPage, 1), newPage);
  };

  const handlePageChange = (newPage: number) => {
    const pageValue = getValidPage(newPage, limit);
    updateSearchParams({ page: pageValue }, { replace: true });
    setPage(pageValue);
  };

  const handleLimitChange = (newLimit: number) => {
    const pageValue = getValidPage(page, newLimit);

    if (pageValue === page) {
      updateSearchParams({ limit: newLimit }, { replace: true });
    } else {
      updateSearchParams({ page: pageValue, limit: newLimit }, { replace: true });
      setPage(pageValue);
    }
    setLimit(newLimit);
    localStorage.setItem(LOCAL_STORE_LIMIT, `${newLimit}`);
  };

  useEffect(() => {
    loadProductSummary({ variables: { options: queryOptions } });
  }, [queryOptions, loadProductSummary]);

  useEffect(() => {
    loadProduct({ variables: { options: queryOptions, pagePagination: { page, limit } } });
  }, [page, limit, queryOptions, loadProduct]);

  useEffect(() => {
    sessionStorage.setItem(SUPERUSER_PRODUCTS_PAGE_LIST, JSON.stringify(data?.superuserProducts || []));
  }, [data?.superuserProducts]);

  const superuserProducts = data?.superuserProducts;
  const count = dataSummary?.superuserProductsSummary?.total || 0;

  return (
    <Paper>
      <StyledBasicTitle>Products awaiting approval</StyledBasicTitle>
      {loadingSummary ? (
        <Box height={86} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={32} variant="indeterminate" />
        </Box>
      ) : (
        !errorSummary &&
        dataSummary?.superuserProductsSummary && (
          <Card>
            <PagePagination
              page={page}
              count={count}
              limit={limit}
              onPageChange={(_, newPage) => handlePageChange(newPage)}
              onLimitChanged={handleLimitChange}
            />
          </Card>
        )
      )}
      {/* TODO any */}
      {(error || networkStatus === 8) && <ErrorBox message={`${error ? (error as any).message : 'Network error'}`} />}
      {/* TODO add loading and error */}
      {!loading && !error && networkStatus !== 8 && (!superuserProducts || superuserProducts.length < 1) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <span
            style={{ fontSize: 18, textAlign: 'center', marginTop: 20, color: COLORS.mainGreen, fontWeight: 'bold' }}
          >
            All products approved. Nothing to do here! :)
          </span>
        </div>
      )}
      <Box p={1.5} maxWidth={500}>
        <TextField
          fullWidth
          placeholder="Search by EAN or Product Name or Producer Name"
          variant="outlined"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
            updateSearchParams({ search: e.target.value });
          }}
        />
      </Box>
      {!error && loading && networkStatus !== 8 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 20,
            paddingBottom: 20,
          }}
        >
          <CircularProgress size={70} variant="indeterminate" />
        </div>
      )}
      {!loading && !error && networkStatus !== 8 && superuserProducts && (
        <>
          <Grid container={true} style={{ padding: 10 }}>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showNotApproved}
                    onChange={() => setShowNotApproved(!showNotApproved)}
                    name="shownotapproved"
                    color="primary"
                  />
                }
                label={showNotApproved ? 'All products' : 'Products by approved producers'}
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showNoPictureEANTitle}
                    onChange={() => setShowNoPictureEANTitle(!showNoPictureEANTitle)}
                    name="showNoPictureEANTitle"
                    color="primary"
                  />
                }
                label={
                  !showNoPictureEANTitle
                    ? 'Only show products with no Picture, EAN and/or Title'
                    : 'Hide products with no Picture, EAN and/or Title'
                }
              />
            </Grid>
            <Grid item={true} xs={4}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showUnderProcessInformationApproved}
                    onChange={() => setShowUnderProcessInformationApproved(!showUnderProcessInformationApproved)}
                    name="showUnderProcessInformationApproved"
                    color="primary"
                  />
                }
                label={
                  showUnderProcessInformationApproved
                    ? 'Hide products with "Picture under process" & "Information approved"'
                    : 'Only show products with "Picture under process" & "Information approved"'
                }
              />
            </Grid>
          </Grid>
          <List dense>
            {superuserProducts &&
              superuserProducts.map(p => (
                <ProductListItem
                  searchString={searchValue}
                  product={p}
                  key={p.id}
                  showNotApproved={showNotApproved}
                  showNoPictureEANTitle={showNoPictureEANTitle}
                  showUnderProcessInformationApproved={showUnderProcessInformationApproved}
                />
              ))}
          </List>
        </>
      )}
      {loadingSummary ? (
        <Box height={86} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={32} variant="indeterminate" />
        </Box>
      ) : (
        !errorSummary &&
        dataSummary?.superuserProductsSummary && (
          <Card>
            <PagePagination
              page={page}
              count={count}
              limit={limit}
              onPageChange={(_, newPage) => handlePageChange(newPage)}
              onLimitChanged={handleLimitChange}
            />
          </Card>
        )
      )}
    </Paper>
  );
};

export default ProductList;
