// Dependencies
import React from 'react';

// Components
import ProductCard from '../../ProductCard/ProductCard';
import CardGrid from '../../../CardGrid';

// Constants
import {IProductCardViewProps} from '../types';
import useViewport from 'hooks/useViewports';

const ProductCardView = ({
     list,
     producerId,
     selectedProductId,
     userResourceRole,
     overrideOnClickCallback,
     notificationsData,
     currentCategory,
     isExport,
     showDescriptiveSize,
     refetch,
     summaryRefetch,
     showEdit,
     showSuperuserNotifications,
     storeMe,
     searchString,
     removeProductHandlerKey,
 }: IProductCardViewProps) => {
    const { smUp, mdUp, lgUp } = useViewport();
    let gridXs = 12;
    if (smUp) gridXs = 6;
    if (mdUp) gridXs = 4;
    if (lgUp) gridXs = 3;

    return (
        <CardGrid list={list} xs={gridXs}>
            {(props: any) => (
                <ProductCard
                    {...props}
                    notificationsData={notificationsData}
                    producerId={producerId}
                    selectedProductId={selectedProductId}
                    userResourceRole={userResourceRole}
                    overrideOnClickCallback={overrideOnClickCallback}
                    currentCategory={currentCategory}
                    isExport={isExport}
                    showDescriptiveSize={showDescriptiveSize}
                    refetch={refetch}
                    summaryRefetch={summaryRefetch}
                    showEdit={showEdit}
                    showSuperuserNotifications={showSuperuserNotifications}
                    storeMe={storeMe}
                    searchString={searchString}
                    removeProductHandlerKey={removeProductHandlerKey}
                />
            )}
        </CardGrid>
    );
};

export default ProductCardView;
