import { makeStyles } from '@material-ui/core/styles';

import COLORS from '../../../constants/colors';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formSelectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submitApprove: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.mainGreen,
  },
  deleteButton: {
    marginTop: 20,
  },
  deleteText: {
    fontSize: 14,
  },
  confirmDeleteText: {
    fontSize: 14,
    fontWeight: 'bolder',
  },
  submitAwait: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.mainOrange,
  },
  submitDisapprove: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: COLORS.mainRed,
  },
  feedbackContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
  },
  failedAIText: {
    //color: COLORS.mainRed,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 1,
  },
  failedAIMessagesText: {
    color: COLORS.mainRed,
    fontSize: 16,
    marginTop: 1,
  },
  imageGuideText: {
    color: COLORS.mainGreen,
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 1,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  note: {
    display: 'block',
    paddingBottom: '10px',
    fontWeight: 700,
    color: theme.palette.primary.dark,
  },
  noteLink: {
    color: "inherit"
  },
  radioLabel: {
    color: "#000",
    lineHeight: '24px',

    '&.Mui-focused': {
      color: "#000",
    },
  },
}));

export default useStyles;
