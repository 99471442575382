import { DependencyList, useEffect, useRef, useState } from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from '@material-ui/core/styles';

import IProduct from "models/product";
import FloatInput from "../FloatInput";
import { isDefined } from "utils/helpers-ts";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControl, FormLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import ListDropDown from "components/inputfields/ListDropDown";
import LocalizationProvider from "@material-ui/pickers/LocalizationProvider";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { DesktopDatePicker } from "@material-ui/pickers";
import svLocale from "date-fns/locale/sv";
import startOfDay from "date-fns/esm/startOfDay";
import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";
import { id } from "date-fns/locale";
import { TAX_CLASSIFICATION } from "components/constants-ts";

const useFirstRender = (func: () => void, dependencies: DependencyList) => {
  const firstRender = useRef(false);

  useEffect(() => {
      if (firstRender.current) func();
      else firstRender.current = true;
  }, dependencies);
}

// const useStyles = makeStyles(theme => ({
//   heading: {
//     fontSize: theme.typography.pxToRem(15),
//     flexBasis: '33.33%',
//     flexShrink: 0,
//   },
//   secondaryHeading: {
//     fontSize: theme.typography.pxToRem(15),
//     color: theme.palette.text.secondary,
//   },
// }));

interface IBasicDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  isFood: boolean;
  isMagazineCategory?: boolean;
}

// detailsEffectiveDate

// basicDataMixCategory
// basicDataTradeItemVariableUnit
// basicDataTaxClassification
// basicDataPriceComparisonContentTypeCode
// basicDataAvailabilityDateFrom
// basicDataAvailabilityDateTo
// basicDataCbuIndicator

// const isEmpty = (product?: IProduct) => {
//   if (!product) return true;
//   if (
//     // isDefined(product.basicDataCategory)
//     product.basicDataTradeItemVariableUnit
//     || product.basicDataCbuIndicator
//     || isDefined(product.basicDataTaxClassification)
//     || isDefined(product.basicDataMixCategory)
//     || (product.basicDataPriceComparisonContentType && product.basicDataPriceComparisonContentType.trim() !== '')
//     || isDefined(product.basicDataAvailabilityDateFrom)
//   ) {
//     return false;
//   }
//   return true;
// };

const BasicData = ({ state, setState, isFood, isMagazineCategory }: IBasicDataProps) => {
  // const classes = useStyles();
  // const onLabelChanged = ({ target }: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   setState({...state, [target.name]: checked }); 
  // };
  // const [expanded, setExpanded] = useState<boolean>(false);
  // useEffect(() => {
  //   setExpanded(!isEmpty(state));
  //   if (state?.basicDataMixCategory) {
  //     setArticleCategory("mix");
  //   } else {
  //     setArticleCategory("single");
  //   }    
  // }, []);
  // const handleAccordionChange = (event: any, isExpanded?: boolean) => {
  //   setExpanded(!!isExpanded);
  // };

  const [isBasicDataTaxClChanged, setIsBasicDataTaxClChanged] = useState(false);

  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      const stateValue = event.target.value;

      const value = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: value });
  };

  useEffect(() => {
    if(state?.basicDataTaxClassification) {
      setIsBasicDataTaxClChanged(true);
    }
  }, [])

  useFirstRender(() => {
    if(isBasicDataTaxClChanged) return;
    if (state.isMagazine) return;

    if (isMagazineCategory) {
      setState({ ...state,  basicDataTaxClassification: 6 });
    } else if (isFood) {
      setState({ ...state,  basicDataTaxClassification: 12 });
    } else {
      setState({ ...state,  basicDataTaxClassification: 25 });
    }
  }, [state.foodlaCategory, isFood, isBasicDataTaxClChanged]);

  // // TODO any
  // const handleRadioChange = (event: any) => {
  //   let stateValue = event.target.value;

  //   setArticleCategory(stateValue);
  // };
  // const [articleCategory, setArticleCategory] = useState<"single"|"mix">("single");
  // useEffect(() => {
  //   setState({ ...state, basicDataMixCategory: articleCategory === "mix" });
  // }, [articleCategory]);
  // const handleChange = ({ target }: any) => setState({ ...state, [target.name]: target.value });
  return (
    <>
    {/* <Accordion expanded={expanded} onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography className={classes.heading}>Basic Data</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{width: '100%'}}>
        // basicDataMixCategory
        // basicDataPriceComparisonContentTypeCode
        // basicDataAvailabilityDateFrom
        // basicDataAvailabilityDateTo
        <FloatInput
          name="basicDataCategory"
          label="Category"
          state={state}
          setState={setState}
          dense={false}
        />
        <FormControl component="fieldset">
          <InputLabel
            heading="Article Category"
            text="Indicate if single article or Mix"
          />
          <RadioGroup
            row
            aria-label="articleCategory" 
            value={articleCategory} 
            name="articleCategory" 
            onChange={handleRadioChange}
          >
              <FormControlLabel
                value="single"
                control={<Radio color="primary" />}
                label="Single Article"
                labelPlacement="start"
              />
              <FormControlLabel
                value="mix"
                control={<Radio color="primary" />}
                label="Mix Article"
                labelPlacement="start"
              />
          </RadioGroup>
        </FormControl>
        <br />
        <InputLabel 
          heading="Trade item variable unit"
          text="Indicate if article weight varies between different articles"
        />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.basicDataTradeItemVariableUnit}
                onChange={onLabelChanged}
                name="basicDataTradeItemVariableUnit"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="Trade item variable unit"
          /> */}
          <InputLabel
            heading="Produktens skattesats"
          />
          <ListDropDown
            disableLabel={true}
            listData={TAX_CLASSIFICATION}
            value={state?.basicDataTaxClassification}
            targetName="basicDataTaxClassification"
            handleChange={(e) => {
              setIsBasicDataTaxClChanged(true);
              handleDropdownChange(e);
            }}
          />
          {/* <InputTextField
            state={state}
            setState={setState}
            inputName="basicDataPriceComparisonContentType"
            value={state.basicDataPriceComparisonContentType}
            label="Price comparison content type"
            onChange={handleChange}
            withLabel
            labelOptions={{
              heading: "Price comparison content type",
              text: `Enter price comparison type. E.g. if washing deterient enter amount of washes you can get from one package.

              For articles handled in pieces value is ST`,
            }}
          /> */}
          {/* <LocalizationProvider dateAdapter={DateFnsUtils} locale={svLocale}>
            <DesktopDatePicker
              label="Availability date from"
              inputFormat="yyyy/MM/dd"
              value={state?.basicDataAvailabilityDateFrom}
              onChange={(newDate) => {
                if (newDate) {
                  const value = startOfDay(newDate).getTime();
                  setState({ ...state, basicDataAvailabilityDateFrom: value });
                } else {
                  setState({ ...state, basicDataAvailabilityDateFrom: null })
                }
              }}
              renderInput={(params) => <TextField {...params} />}
              clearable
            />
          </LocalizationProvider>
          <br />
          <InputLabel
            heading="CBU Indicator"
            text="Under CBU indicator, if any, indicate whether the article is offered with CBU"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state?.basicDataCbuIndicator}
                onChange={onLabelChanged}
                name="basicDataCbuIndicator"
                color="primary"
                style={{ paddingLeft: 0 }}
              />
            }
            labelPlacement="end"
            label="CBU Indicator"
          />
        </div>
      </AccordionDetails>
    </Accordion> */}
  </>
)};

export default BasicData;
