import React from "react";

import InputLabel from "components/inputfields/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ListDropDown from "components/inputfields/ListDropDown";
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import {
  complianceSunscreenRegulation,
  sunProtectionFactor,
} from '../../../constants-ts';


import IProduct from "models/product";
import { isSunscreen } from "./category";
import { IFoodlaCategoryOption } from "models/category";

interface ISunScreenFieldsDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  rootCategory: IFoodlaCategoryOption;
};

const SunScreenFields = ({ state, setState, rootCategory }: ISunScreenFieldsDataProps) => {
  const handleDropdownChange = (
    event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>,
  ) => {
      const stateName = event.target.name;
      let stateValue = event.target.value;

      const val = !stateValue || stateValue === '' ? null : stateValue;
      stateName && setState({ ...state, [stateName]: val });
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;

    const formattedValue = value ?  value === "true" : null;

    setState({ ...state, [name]: formattedValue });
  };

  return (
    <div style={{width: '100%', marginTop: '7px'}}>
      <FormControl component="fieldset">
        <InputLabel 
          heading="Överensstämmer produkten med kraven i kosmetikaförordningen (EG) 1223/2009?"
          text="Produkten som tillhandahålls på marknaden ska uppfylla kraven i förordningen, inklusive kraven på effekt och säkerhetsrapport."
          link="https://eur-lex.europa.eu/legal-content/SV/TXT/PDF/?uri=CELEX:02009R1223-20220301&from=EN"
          required
        />
        <RadioGroup
          value={state?.complianceEg12232009}
          name="complianceEg12232009"
          style={{ flexDirection: "row", gridGap: 16 }}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            label="Ja"
            labelPlacement="start"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            label="Nej"
            labelPlacement="start"
          />
          <FormControlLabel
            value={null}
            control={<Radio color="primary" />}
            label="Ej valt"
            labelPlacement="start"
          />
        </RadioGroup>
      </FormControl>
      <InputLabel
        heading="Uppfyller produkten som är eller innehåller solskyddsmedel de preciserade reglerna?"
        text="Det finns vissa regler som gäller solskyddsmedel produkter."
        link="https://www.lakemedelsverket.se/sv/kosmetika/innehall-i-kosmetika/solskyddsprodukter"
        required={isSunscreen(state?.foodlaCategory, rootCategory)}
      />
      <ListDropDown
        disableLabel={true}
        listData={complianceSunscreenRegulation}
        value={state?.complianceSunscreenRegulation}
        targetName="complianceSunscreenRegulation"
        handleChange={handleDropdownChange}
        displayEmpty={true}
      />
      <InputLabel
        heading="Solskyddsfaktor (SPF)"
        text="När tester visat att en produkt ger skydd mot UV-strålning ska den märkas med solskyddsfaktor och skyddskategori. Tillåtna solskyddsfaktorer som får anges i märkningen är (SPF); 6, 10, 15, 20, 25, 30, 50 och 50+."
        link="https://www.lakemedelsverket.se/sv/kosmetika/innehall-i-kosmetika/solskyddsprodukter#hmainbody3"
        required={isSunscreen(state?.foodlaCategory, rootCategory)}
      />
      <ListDropDown
        disableLabel={true}
        listData={sunProtectionFactor}
        value={state?.sunProtectionFactor}
        targetName="sunProtectionFactor"
        handleChange={handleDropdownChange}
        displayEmpty={true}
      />
    </div>
  );
}

export default SunScreenFields;
