import React, { useState } from "react";

import InputLabel from "components/inputfields/InputLabel";
import InputTextField from "components/inputfields/InputTextField";

import IProduct from "models/product";

interface IShortSalesDataProps {
  state: IProduct;
  setState: (state: IProduct) => void;
  disableSubmitButton: (value: boolean) => void;
}

interface NewInputErrors {
  shortSalesDescription?: boolean,
}

const SHORT_SALES_DESCRIPTION_MAX_LENGTH = 25;
const SHORT_SALES_DESCRIPTION_SEPARATORS = /[ ,:;.]+/;

const getShortSalesDescriptionWordLength = (value: string | undefined): number => {
  const trimmedValue = value?.trim();
  const shortSalesDescriptionWordArray = trimmedValue?.split(SHORT_SALES_DESCRIPTION_SEPARATORS).filter(value => value !== "");
  return shortSalesDescriptionWordArray?.length ?? 0;
};

const getIsInputValid = (name: string, value: string) => {
  switch (name) {
    case 'shortSalesDescription':
      return getShortSalesDescriptionWordLength(value) <= SHORT_SALES_DESCRIPTION_MAX_LENGTH;
    default: 
      return true;
  }
};

const ShortSalesDescription = ({ state, setState, disableSubmitButton}: IShortSalesDataProps) => {

    const [errors, setErrors] = useState<NewInputErrors>({});

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;

      const isValid = getIsInputValid(name, value);
      
      disableSubmitButton(!isValid);
      setErrors({ ...errors, [name]: !isValid});
      setState({ ...state, [name]: value });
    };

    return (
        <div style={{width: '100%'}}>
            <InputLabel
            heading="Skriv en kort säljande beskrivning av produkten"
            text="Här skriver du en mening som säljer och beskriver produkten."
            />
            <InputTextField
            state={state}
            setState={setState}
            inputName="shortSalesDescription"
            value={state.shortSalesDescription}
            placeholder={`Skriv en mening på max ${SHORT_SALES_DESCRIPTION_MAX_LENGTH} ord`}
            forceRed={!!errors.shortSalesDescription}
            onChange={handleChange}
            />
            <span style={{
            display: 'flex',
            justifyContent: 'right',
            color: errors.shortSalesDescription ?'orangered' : 'black'
            }}>
            {`${getShortSalesDescriptionWordLength(state?.shortSalesDescription)}/${SHORT_SALES_DESCRIPTION_MAX_LENGTH}`}
            </span>
        </div>
      );
}

export default ShortSalesDescription;
