import { FC, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import DataImport from './DataImport';
import FieldsMapping from './FieldsMapping';
import DataReviewing from './DataReviewing';
import { makeStyles } from '@material-ui/core/styles';

import { ControlProvider, DataProvider } from './Contexts';
import useControlContext from './hooks/useControlContext';
import useLayoutContext from 'hooks/useLayoutContext';
import CategorySelect from './CategorySelect';
import { GET_PRODUCER_ALL } from 'graphql/queries';
import useCategoriesData from 'hooks/useCategoriesData';
import ConfirmContinueModal from './components/ConfirmContinueModal';
import { getStepNumber } from './utils';
import { LOCAL_STORE_ITEMS } from './constants';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    color: '#383D52',
    fontSize: 14,
    height: '100%',
    paddingBottom: 36,
    '& img': {
      display: 'block',
    },
  },
}));

const BulkImportBase = () => {
  const classes = useStyles();
  const { loading: loadingProducerData, data: queryProducerData } = useQuery(GET_PRODUCER_ALL, {
    variables: { showAll: true },
  });
  const { loadingLocal, step, setProducerList } = useControlContext();

  useEffect(() => {
    if (queryProducerData?.producers?.data?.length) {
      setProducerList(queryProducerData?.producers.data);
    }
  }, [queryProducerData, setProducerList]);

  if (loadingLocal) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        Loading...
      </Box>
    );
  }

  if (loadingProducerData) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        Loading producers...
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      {step === 1 && <CategorySelect />}
      {step === 2 && <DataImport />}
      {step === 3 && <FieldsMapping />}
      {step === 4 && <DataReviewing />}

      <Box my={3} sx={{ height: 1, bgcolor: '#D7DAE4' }} />
      <Box display="flex" gridGap={4} mb={3} fontSize={16}>
        <span>Fler frågor? Hör av dig till <a href="mailto:support@foodla.nu">support@foodla.nu</a> så hjälper vi dig!</span>
        {/* <span>Fler frågor? Kolla in</span>
        <a
          href="https://help.foodla.nu/knowledge/hur-importerar-jag-produkter-med-bulk-import"
          target="_blank"
          rel="noreferrer"
        >
          FAQ
        </a>
        <span>
          för detaljerade instruktioner för bulkimporten eller <a href="mailto:support@foodla.nu">support@foodla.nu</a>!
        </span> */}
      </Box>
    </Box>
  );
};

interface BulkImportProps {
  userRole: string;
}

const BulkImport: FC<BulkImportProps> = ({ userRole }) => {
  const { step: stepPath } = useParams<{ step: string }>();
  const { isCheckingProducer } = useLayoutContext();
  const { loading: loadingCategories, rootCategory, categories: allCategories } = useCategoriesData();

  const [showConfirmContinue, setShowConfirmContinue] = useState(false);

  useEffect(() => {
    const step = getStepNumber(stepPath);
    if (step === null) return;
    // after get "step" from url, will update localstage value
    // then "step" will be loaded from localstage in [ControlContext]
    localStorage.setItem(LOCAL_STORE_ITEMS.STEP, String(step));
  }, [stepPath]);

  if (isCheckingProducer) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        Checking producer...
      </Box>
    );
  }

  if (loadingCategories) {
    return (
      <Box display="flex" justifyContent="center" py={10}>
        Loading categories...
      </Box>
    );
  }

  if (!rootCategory?.children?.length || !allCategories?.length) {
    return (
      <Box display="flex" justifyContent="center" py={10} color="red">
        Can't load categories
      </Box>
    );
  }

  return (
    <ControlProvider userRole={userRole}>
      <DataProvider rootCategory={rootCategory} allCategories={allCategories}>
        <ConfirmContinueModal open={showConfirmContinue} setOpen={setShowConfirmContinue} />

        <BulkImportBase />
      </DataProvider>
    </ControlProvider>
  );
};

export default BulkImport;
