import { useCallback, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const toStringSearchValues = (searchParams: Record<string, string | number>) => {
  const result: Record<string, string> = {};
  Object.entries(searchParams).forEach(([key, value]) => (result[key] = String(value)));
  return result;
};

const useSearchParams = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const keepRef = useRef<{ searchParams: Record<string, string> }>({ searchParams: {} });

  keepRef.current.searchParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return Object.fromEntries(searchParams.entries());
  }, [search]);

  const updateSearchParams = useCallback(
    (newSearchParams: Record<string, string | number>, options?: { replace: boolean }) => {
      const search = new URLSearchParams(
        toStringSearchValues({ ...keepRef.current.searchParams, ...newSearchParams })
      ).toString();

      if (options?.replace) {
        history.replace({ pathname, search });
      } else {
        history.push({ pathname, search });
      }
    },
    [pathname, history]
  );

  const setSearchParams = useCallback(
    (newSearchParams: Record<string, string | number>, options?: { replace: boolean }) => {
      const search = new URLSearchParams(toStringSearchValues(newSearchParams)).toString();

      if (options?.replace) {
        history.replace({ pathname, search });
      } else {
        history.push({ pathname, search });
      }
    },
    [pathname, history]
  );

  return {
    searchParams: keepRef.current.searchParams,
    updateSearchParams,
    setSearchParams,
  };
};

export default useSearchParams;
